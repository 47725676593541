<template>
    <div>
        <nut-grid :column-num="2" icon-color="#fa2c19" >
            <nut-grid-item :icon="require('../background/icons/line_chart_against.svg')" icon-size="50"
                           to="/vix" text="贪恐指数"></nut-grid-item>
            <!--            <nut-grid-item :icon="require('../background/icons/bar_chart_horizontal_sorted.svg')" icon-size="50" text="排名"></nut-grid-item>-->
            <!--            <nut-grid-item :icon="require('../background/icons/line_chart_against.svg')" icon-size="50" text="叉点"></nut-grid-item>-->
            <!--            <nut-grid-item :icon="require('../background/icons/single_area_chart.svg')" icon-size="50" text="趋势"></nut-grid-item>-->
        </nut-grid>
    </div>
</template>

<script>
    export default {
        name: ""
    }
</script>

<style scoped>

</style>
