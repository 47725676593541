<template>
    <div class="video-container">
      <CanvasBackground style="z-index: -1">
      </CanvasBackground>
        <div class="logo_box">
            <h3>灿韵灵越灵芯后台系统</h3>
            <form action="#" name="f" method="post">
                <div class="input_outer">
                    <span class="u_user"></span>
                    <input v-model="username" class="textU" style="" type="text"
                           placeholder="请输入账户">
                </div>
                <div class="input_outer">
                    <span class="us_uer"></span>
                    <input v-model="password" class="textU" style="" type="password"
                           placeholder="请输入账户"/>
                </div>
                <div class="mb2">
                    <nut-button class="act-but " type="primary" @click.native="login"
                    >登陆</nut-button>
                </div>
            </form>
            <a class="el-login-footer" href="https://beian.miit.gov.cn">Copyright 北京灿韵灵越科技有限公司 2020-2022
                京ICP备2020043180号
                公安备案号:</a>
        </div>
        </div>
</template>

<script>
// import VideoBackground from "../../components/VideoBackground";
import CanvasBackground from "../../components/CanvasBackground";
import axios from "axios";
import {Notify} from "@nutui/nutui";

export default {
  data() {
    return {
      username: "",
      password: "",
    };
  },
  components: {
    CanvasBackground,
  },
  mounted() {
    if (this._isMobile()) {
      // 手机端
      this.$router.replace("/phonelogin");
    }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    login() {
      let p = {
        userName: this.username,
        password: this.password,
      };
      var vm = this;
      axios({method: "post", url: "/login/userLogin", params: p}).then(
          (res) => {
            console.log(res);
            if (res.data.code === 0) {
              localStorage.setItem("stockAdminAuth", res.data.data.token);
              localStorage.setItem("cKey", res.data.data.cKey);
              console.log(localStorage.getItem("cKey"))
              Notify.success('登陆成功');
              vm.$router.push({path: "/pcMain"});
            } else {
              Notify.danger(res.data.msg);
            }
          }
      );
    },
  },
};
</script>

<style scoped>
    input:-webkit-autofill {
        -webkit-text-fill-color: #EEEEEE !important;/*浏览器记住密码的字的颜色*/
        transition: background-color 5000s ease-in-out 0s;/*通过延时渲染背景色变相去除背景颜色*/
        caret-color: #EEEEEE;/*光标颜色*/
        font-size: medium;
    }
    .logo_box {
        width: 400px;
        height: 500px;
        padding: 35px;
        color: #EEE;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -200px;
        margin-top: -250px;
    }

    .logo_box h3 {
        text-align: center;
        height: 20px;
        font: 20px "microsoft yahei", Helvetica, Tahoma, Arial, "Microsoft jhengHei", sans-serif;
        color: #FFFFFF;
        height: 20px;
        line-height: 20px;
        padding: 0 0 35px 0;
    }


    .input_outer {
        height: 46px;
        padding: 0 5px;
        margin-bottom: 30px;
        border-radius: 50px;
        position: relative;
        border: rgba(255, 255, 255, 0.2) 2px solid !important;
    }

    .u_user {
        width: 25px;
        height: 25px;
        background: url(../../background/img/login_ico.png);
        background-position: -125px 0;
        position: absolute;
        margin: 10px -55px;
    }

    .us_uer {
        width: 25px;
        height: 25px;
        background-image: url(../../background/img/login_ico.png);
        background-position: -125px -34px;
        position: absolute;
        margin: 10px -55px;
    }

    .textU {
        position: relative;
        background-color:transparent;
        width: 220px;
        height: 46px;
        outline: none;
        display: inline-block;
        font: medium "microsoft yahei", Helvetica, Tahoma, Arial, "Microsoft jhengHei";
        margin-left: 5px;
        border: none;
        line-height: 46px;
        color: #EEEEEE;

    }
    .el-login-footer {
        height: 40px;
        line-height: 40px;
        position: fixed;
        bottom: 0;
        left: 0;
        margin-left: 36%;
        text-align: left;
        color: #EEEEEE;
        font-family: Arial;
        font-size: 12px;
        letter-spacing: 1px;
    }
    /*///*/
    /*.mb2 {*/
    /*    margin-bottom: 20px*/
    /*}*/

    /*.mb2 a {*/
    /*    text-decoration: none;*/
    /*    outline: none;*/
    /*}*/

    .submit {
        padding: 15px;
        margin-top: 20px;
        display: block;
    }

    .act-but {
        line-height: 20px;
        text-align: center;
        font-size: 20px;
        border-radius: 50px;
        background: #0096e6;
        width: 400px;
    }

</style>
