<template>
    <div>
        <!--        <canvas id="planet-chart"></canvas>-->
        <div id="echart-line" :style="{width: '100%', height: '500%'}"></div>
    </div>
</template>

<script>
    // import {Line, Chart, Bar} from 'chart.js';
    // import planetChartData from '../../js/pdata'
    import * as echarts from 'echarts'
    import axios from "axios";
    // import { Bar } from 'vue-chartjs'
    export default {
        name: "",
        data() {
            // return {
            //     planetChartData: planetChartData
            // }
        },
        methods: {
            getVixCompareData(startDay, endDay) {
                let p = {
                    startDay: startDay,
                    endDay: endDay
                }
                axios({method: "post", url: "/vix/getVixCompare", params: p}).then(
                    (res) => {
                        if (res.data.code === 0) {
                            var days=res.data.data.days
                            var vals=res.data.data.vals
                            var vSources=res.data.data.vSources
                            this.initChart(days, vSources,vals)

                        } else {
                            console.log(res.data.msg)
                        }
                    }
                );
            },
            initChart(xData, data1, data2) {
                let getchart = echarts.init(document.getElementById('echart-line'));
                var option = {
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {
                        data: ['yujian'],
                        bottom: '0%'
                    },
                    grid: { //调整图表上下左右位置
                        top: '10%',
                        left: '10%',
                        right: '10%',
                        bottom: '0%',
                        containLabel: true
                    },

                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: xData
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {   name:'原数据',
                            data: data1,
                            type: 'line',
                            stack: 'x'
                        },
                        {
                            name:'自己计算',
                            data: data2,
                            type: 'line',
                            stack: 'x'
                        }
                        // {
                        //     name: name,
                        //     type: 'line',
                        //     stack: '总量',
                        //     data: yData
                        // },
                    ]
                };

                getchart.setOption(option);
                //随着屏幕大小调节图表
                window.addEventListener("resize", () => {
                    getchart.resize();
                });
            },
        }, mounted() {
            console.log(111111)
            this.getVixCompareData(20150210, 20180705)

            // const ctx = document.getElementById('planet-chart');
            // new Chart(ctx, this.planetChartData);
        }
    }
</script>

<style scoped>

</style>
