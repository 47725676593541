<template>
    <div>
        <div  style="margin-top: 45px">
            <div v-for="c in yzMapping" >
                <nut-row>
                    <nut-col>
                        <div class="flex-content" @click="this.divClick(c[0])">{{c[1]}}</div>
                    </nut-col>
                </nut-row>
                <nut-row>
                    <nut-col :span="24">
                        <nut-grid :column-num="3">
                            <nut-grid-item v-for="r in c[2]" :icon="r[1]" :text="r[0]" :to="{
                            name:r[2],
                            params:{
                                busType:r[4],
                                img:r[1],
                                t:r[0],
                                busName:r[3]
                            }
                        }"></nut-grid-item>
                        </nut-grid>
                    </nut-col>
                </nut-row>
                <nut-divider/>
            </div>
        </div>


        <nut-navbar @on-click-back="back" left-show fixed ref="nbar"
                    :titIcon="require('../../background/icons/line_chart.svg')" title="上涨因子">
            <template #left>
                <nut-cell title="返回" @click="back"></nut-cell>
            </template>
        </nut-navbar>

        <nut-overlay v-model:visible="ovShow" :z-index="2000">
            <div>
                <!--                <nut-switch v-model="checked" size="15px" />-->
                <nut-skeleton width="250px" height="15px" title animated avatar row="3" :loading=false>
                    <div class="ct">
                        <nut-avatar class="na"
                                    size="80"
                                    :icon="ovI"
                        />
                        <div class="rc">
                            <div class="t">{{ovT}}</div>
                            <div class="desc">&#12288;&#12288;{{ovF}}</div>

                        </div>
                    </div>
                    <div class="ct">
                        <div class="rc">
                            <div class="desc" v-for="c in ovC">&#12288;&#12288;{{c}}</div>
                        </div>
                    </div>
                </nut-skeleton>
            </div>
        </nut-overlay>
        <nut-backtop ></nut-backtop>
    </div>
</template>

<script>
    import allMapping from '../../const/introduceMapping'
    export default {
        data() {
            return {
                ovShow: false,
                checked: true,
                ovT: '',
                ovF: '',
                ovC: [],
                ovI: '',
                ovMapping: {},
                yzMapping: {}
            }
        },
        mounted () {
            this.ovMapping = allMapping['ovMapping']
            this.yzMapping = allMapping['yzMapping']
        }
        ,
        methods: {
            back() {
                this.$router.push({path: "/pcMain"});
            },
            divClick(busName) {
                this.ovT = this.ovMapping[busName][0]
                this.ovF = this.ovMapping[busName][1]
                this.ovC = this.ovMapping[busName][2]
                this.ovI = this.ovMapping[busName][3]
                this.ovShow = true
            }
        }
    }
</script>

<style lang="scss" scoped>
    .flex-content {
        line-height: 40px;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        background: #ff8881;
    }

    .nut-row {
        overflow: hidden;

        &:not(:last-child) .nut-col {
            margin-bottom: 15px;
        }

        margin-bottom: 15px;
    }

    .wrapper {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;

        .content {
            display: flex;
            width: 150px;
            height: 150px;
            background: #fff;
            border-radius: 8px;
            align-items: center;
            justify-content: center;
            color: red;
        }
    }

    .ct {
        display: flex;
    }

    .rt {
        margin-left: 10px;
        display: flex;
        flex-direction: column;
    }

    .rc {
        margin-left: 12px;
        display: flex;
        flex-direction: column;
    }

    .na {
        margin-left: 15px;
        margin-top: 15px;
    }

    .t {
        margin-top: 15px;
        font-size: 26px;
        color: white;
    }

    .desc {
        margin-top: 5px;
        font-size: 18px;
        color: #e9d0a7;
        text-align: left;
    }
</style>
