<template>
    <div ref="element1" style="background-color: #FFF2E2;">
        <div style="height: auto;background-color: #FFF2E2;" ref="element">
            <nut-navbar @on-click-back="back" left-show style="margin-bottom: 5px"
                        :titIcon="tIcon" :title="t">
                <template #left>
                    <nut-cell title="返回" @click="back"></nut-cell>
                </template>
            </nut-navbar>
            <nut-form style="margin-top: -10px">
                <nut-form-item required label-align="center" style="padding: 10px" label-width="18%" label="数据类型:">
                    <nut-radiogroup @change="typeChange" v-model="dataType" direction="horizontal">
                        <nut-radio :disabled="isLoading" label="stocks">个股</nut-radio>
                        <nut-radio :disabled="isLoading" label="indices">指数</nut-radio>
                    </nut-radiogroup>
                </nut-form-item>
                <nut-form-item required label-align="center" style="padding: 10px" label-width="18%" label="日期:">
                    <nut-radiogroup @change="dateChange" v-model="dateChoose" direction="horizontal">
                        <nut-radio :disabled="isLoading" label="1">最近交易{{unit}}</nut-radio>
                        <nut-radio :disabled="isLoading" label="2">前一{{unit}}</nut-radio>
                        <nut-radio :disabled="isLoading" label="3">选择</nut-radio>
                        <div v-show="chooseShow">
                            <nut-cell title="日期:" style="width:100%" :desc="desc" @click="ndShow = true"></nut-cell>
                            <nut-datepicker
                                    v-model="currentDate"
                                    v-model:visible="ndShow"
                                    :min-date="new Date(1992, 0, 1)"
                                    :max-date="maxDay"
                                    :is-show-chinese="true"
                                    @confirm="confirm"
                            ></nut-datepicker>
                        </div>
                    </nut-radiogroup>
                </nut-form-item>

                <nut-form-item required label-align="center" style="padding: 10px" label-width="18%" label="周期:">
                    <nut-radiogroup v-model="period" @change="unitChange" direction="horizontal">
                        <nut-radio :disabled="isLoading" label="1">日线</nut-radio>
                        <nut-radio style="margin-left: 15px" :disabled="isLoading"
                                   label="2">周线
                        </nut-radio>
                        <nut-radio style="margin-left: 15px" :disabled="isLoading" label="3">月线
                        </nut-radio>
                    </nut-radiogroup>
                </nut-form-item>
                <nut-form-item v-show="busName==='boll'" required label-align="center" style="padding: 10px"
                               label-width="18%" label="价格:">
                    <nut-radiogroup v-model="this.preMaster" @change="actChange" direction="horizontal">
                        <nut-radio shape="button" label="high">最高价
                        </nut-radio>
                        <nut-radio shape="button" label="low">最低价
                        </nut-radio>
                        <nut-radio shape="button" label="close">收盘价
                        </nut-radio>
                    </nut-radiogroup>
                </nut-form-item>
                <div v-show="busName!=='avg'||busType!=='access'">
                    <nut-form-item required label-align="center" style="padding: 10px"
                                   label-width="18%" label="趋势:">
                        <nut-radiogroup v-model="this.eventDir" @change="eventChange" direction="horizontal">
                            <nut-radio shape="button" label="1">{{busType==='runUp'?'突破':'金叉'}}
                            </nut-radio>
                            <nut-radio shape="button" label="-1">{{busType==='runUp'?'跌破':'死叉'}}
                            </nut-radio>
                        </nut-radiogroup>
                    </nut-form-item>
                </div>

                <div v-show="busName==='avg'">
                    <nut-form-item v-show="busType==='runUp'" required label-align="center" style="padding: 10px"
                                   label-width="18%" label="参数:">
                        <nut-radiogroup v-model="this.guest" @change="numsChange" direction="horizontal">
                            <nut-radio :disabled="isLoading" v-for="d in days" shape="button" :label="d">{{d}}{{unit}}线
                            </nut-radio>
                        </nut-radiogroup>
                    </nut-form-item>
                    <nut-form-item v-show="busType==='access'" required label-align="center" style="padding: 10px"
                                   label-width="25%" label="叉线选择:">
                        <input
                                class="nut-input-text"
                                style="color: red;font-weight: 700;"
                                @click="this.chooseVisible = true"
                                :value="accessChooseText"
                                readonly
                                placeholder="点击选择叉线"
                                type="text"
                        />
                        <nut-cascader
                                title="叉线选择"
                                v-model="chooseV"
                                v-model:visible="chooseVisible"
                                @change="accessChange"
                                :options="chooseOptions"
                        ></nut-cascader>
                    </nut-form-item>

                </div>
                <nut-form-item v-show="busName==='boll'" required label-align="center" style="padding: 10px"
                               label-width="18%" label="轨线:">
                    <nut-radiogroup v-model="this.preGuest" @change="trackChange" direction="horizontal">
                        <nut-radio shape="button" label="up_">上轨
                        </nut-radio>
                        <nut-radio shape="button" label="mb_">中轨
                        </nut-radio>
                        <nut-radio shape="button" label="dn_">下轨
                        </nut-radio>
                    </nut-radiogroup>
                </nut-form-item>
                <nut-form-item v-show="busType ==='single'" required label-align="center"
                               style="padding: 10px"
                               label-width="18%" label="参数:">
                    <nut-radiogroup v-model="this.guest" @change="paramChange" direction="horizontal">
                        <nut-radio :disabled="isLoading" v-for="d in days" shape="button" :label="d">
                            {{busName}}{{d}}
                        </nut-radio>
                    </nut-radiogroup>
                </nut-form-item>
                <nut-form-item v-show="busType ==='double'" required label-align="center"
                               style="padding: 10px"
                               label-width="18%" label="参数:">
                    <nut-radiogroup v-model="this.doubleTemp" @change="doubleChange" direction="horizontal">
                        <nut-radio :disabled="isLoading" v-for="d in days" shape="button" :label="d">
                            {{busName==='rsi'?(JSON.parse(d)[0]+'_'+JSON.parse(d)[1]):JSON.parse(d)[0]}}
                        </nut-radio>
                    </nut-radiogroup>
                </nut-form-item>

            </nut-form>
        </div>
        <nut-row>
            <nut-col>
                <div class="flex-content" @click="this.divClick(c[0])">{{title}}</div>
            </nut-col>
        </nut-row>
        <ul class="infiniteUl" id="scroll" :style="'height: '+this.h+'px'">
            <nut-infiniteloading
                    containerId='scroll'
                    :use-window='false'
                    :has-more="hasMore"
                    @load-more="loadMore"
            >
                <nut-cell v-for="(r,index) in rows"
                          :key="index"
                          title=""
                          sub-title=""
                          class="infiniteLi "
                          style=" text-align: left;background-color: #FDE6E0;">
                    <template v-slot:icon>
                        <div>
                            <div style="display: inline-block;font-size: large">
                                {{index+1}}.
                            </div>
                            <div @click="clickName(r['c'])"
                                 style="display: inline-block;width:25%;position: absolute; color: #f05b72;background-color: #FDE6E0;">
                                <div>
                                    <nut-tag style="font-size: large;color: #0096e6" plain>{{r['name']}}</nut-tag>
                                </div>
                                <div style="font-size: large">
                                    {{this.addtionCode(r['c'])}}
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-slot:link>
                        <div style="margin-left: 30%;color: #0096e6;background-color: #FDE6E0;">
                            <div v-show="busType==='runUp'&&busName!=='boll'" @click="clickName(r['c'])"
                                 style="float: left;width: 60%">
                                股价
                                <nut-icon
                                        :name="require(r['masterD']===-1 ? '../background/icons/arrow-up.svg':'../background/icons/arrow-down.svg')"
                                        color="#fa2c19"></nut-icon>
                                {{r['continuity']}}交易{{unit}}后重新站上{{nums}}{{unit}}线
                                <nut-icon
                                        :name="require(r['masterD']===-1?'../background/icons/arrow-up.svg':'../background/icons/arrow-down.svg')"
                                        color="#fa2c19"></nut-icon>
                            </div>
                            <div v-show="busName==='boll'" @click="clickName(r['c'])"
                                 style="float: left;width: 60%">
                                {{r['continuity']}}交易{{unit}}后{{preMaster==='close'?'收盘价':(this.preMaster==='low'?'最低价':'最高价')}}
                                <nut-icon
                                        :name="require(r['masterD']===-1 ? '../background/icons/arrow-up.svg':'../background/icons/arrow-down.svg')"
                                        color="#fa2c19"></nut-icon>
                                {{this.eventDir === '1' ? '突破' :
                                '跌破'}} {{this.preGuest==='up_'?'上轨':(this.preGuest==='dn_'?'下轨':'中轨')}}
                                <nut-icon
                                        :name="require(r['masterD']===-1?'../background/icons/arrow-up.svg':'../background/icons/arrow-down.svg')"
                                        color="#fa2c19"></nut-icon>
                            </div>
                            <div v-show="busType==='access'" @click="clickName(r['c'])"
                                 style="float: left;width: 60%">
                                {{r['continuity']}}交易{{unit}}后{{masterNums}}{{unit}}线
                                <nut-icon
                                        :name="require(r['masterD']===-1 ? '../background/icons/arrow-up.svg':'../background/icons/arrow-down.svg')"
                                        color="#fa2c19"></nut-icon>
                                {{this.eventDir === '0' ? '死叉' :
                                '金叉'}} {{guest}}{{this.unit}}线
                                <nut-icon
                                        :name="require(r['masterD']===-1?'../background/icons/arrow-up.svg':'../background/icons/arrow-down.svg')"
                                        color="#fa2c19"></nut-icon>
                            </div>
                            <div v-show="busType==='single'||busType ==='non'" @click="clickName(r['c'])"
                                 style="float: left;width: 60%">
                                {{r['continuity']}}交易{{unit}}后{{preMaster}}{{guest}}
                                <nut-icon
                                        :name="require(r['masterD']===-1 ? '../background/icons/arrow-up.svg':'../background/icons/arrow-down.svg')"
                                        color="#fa2c19"></nut-icon>
                                {{eventDir==='1'?'金叉':'死叉'}} {{preGuest}}{{masterNums}}
                                <nut-icon
                                        :name="require(r['masterD']===-1?'../background/icons/arrow-up.svg':'../background/icons/arrow-down.svg')"
                                        color="#fa2c19"></nut-icon>
                            </div>
                            <div v-show="busType==='double'" @click="clickName(r['c'])" style="float: left;width: 60%">
                                {{r['continuity']}}交易{{unit}}后{{preMaster}}{{guest}}
                                <nut-icon
                                        :name="require(r['masterD']===-1 ? '../background/icons/arrow-up.svg':'../background/icons/arrow-down.svg')"
                                        color="#fa2c19"></nut-icon>
                                {{eventDir==='1'?'金叉':'死叉'}} {{preGuest}}{{masterNums}}
                                <nut-icon
                                        :name="require(r['masterD']===-1?'../background/icons/arrow-up.svg':'../background/icons/arrow-down.svg')"
                                        color="#fa2c19"></nut-icon>
                            </div>

                            <div style="width: 35%; margin-left: 5%;float: right;">
                                <nut-button @click="toDetail(r['c'],r['market'])"
                                            style="margin-right: 1%;float: right"
                                            color="#7232dd"
                                            plain>
                                    行情
                                </nut-button>
                            </div>
                        </div>
                    </template>
                </nut-cell>
                <nut-backtop></nut-backtop>
            </nut-infiniteloading>
        </ul>

        <nut-overlay v-model:visible="urlShow" :z-index="2000" :close-on-click-overlay="false">
            <nut-navbar
                    style="height:40px;display: block;margin-bottom: 0"
            >
                <template #left>
                    <nut-cell title="返回" @click="titleClick"></nut-cell>
                </template>
                <template #content>
                    <nut-radiogroup @change="changeSource" v-model="detailSource" direction="horizontal">
                        <nut-radio label="1">同</nut-radio>
                        <nut-radio label="2">东</nut-radio>
                        <nut-radio label="3">雪</nut-radio>
                    </nut-radiogroup>
                </template>
                <template #right>
                    <nut-button style="height: 30px;width: auto;" @click="iback"
                                shape="square" type="primary" icon="refresh2">重置
                    </nut-button>
                </template>
            </nut-navbar>
            <nut-empty image-size="80%" style="margin-top:-40px;width:100%;height:100%;position:absolute;z-index: -999;"
                       image="empty" description="出错了,请点击上方重置按钮">
            </nut-empty>
            <iframe id="myiframe" :src="this.ifsrc" width="100%" :height="iframeHeight"
                    :style="'margin-top: '+this.ifMaginTop+'px;'"
                    scrolling="auto" security="restricted" sandbox="allow-scripts allow-same-origin allow-popups"
                    frameborder="no" border="0" marginwidth="0" marginheight="0" allowtransparency="no">
            </iframe>
        </nut-overlay>
    </div>
</template>

<script>
    import {ref} from 'vue';
    import {Toast} from '@nutui/nutui';
    import axios from "axios";
    import allMapping from '../const/introduceMapping'

    export default {
        name: "",
        props: {
            t: String,
            tIcon: String,
            busType: String,
            busName: String,
        },

        mounted() {
            this.h = document.documentElement.clientHeight - this.$refs.element.offsetHeight
            this.days = allMapping['daysAccessMapping'][this.busName]
            this.preMaster = allMapping['mgMapping'][this.busName][0]
            this.preGuest = allMapping['mgMapping'][this.busName][1]
            if (this.busName === 'avg') {
                if (this.busType === 'runUp') {
                    this.masterNums = 1
                } else {
                    this.masterNums = 5
                    this.guest = 10
                    this.chooseOptions = this.createChooseArr()
                }
            } else if (this.busName === 'arbr') {
                this.masterNums = 10
                this.guest = 10
                this.event = '-1'
            } else if (this.busName === 'bbi') {
                this.masterNums = ''
                this.guest = ''
                this.event = '1'
            } else if (this.busName === 'boll') {
                this.masterNums = ''
                this.preMaster = 'close'
                this.preGuest = 'mb_'
                this.eventDir = '1'
                this.guest = '20'
            } else if (this.busName === 'dma') {
                this.doubleTemp = this.days[1]
            } else if (this.busName === 'dmi') {
                this.doubleTemp = this.days[1]
            } else if (this.busName === 'dpo') {
                this.doubleTemp = this.days[1]
            } else if (this.busName === 'kdj') {
                this.guest = this.days[1]
            } else if (this.busName === 'macd') {
                this.doubleTemp = this.days[3]
            } else if (this.busName === 'mtm') {
                this.doubleTemp = this.days[2]
            } else if (this.busName === 'trix') {
                this.doubleTemp = this.days[1]
            } else {
                this.masterNums = 10
                this.guest = 10
                this.eventDir = '1'
            }
            this.getData('mounted')

        },
        methods: {
            doubleChange(value) {
                var arr = JSON.parse(value)
                this.masterNums = arr[0]
                this.guest = arr[1]
                this.getData('doubleChange')
            },
            guestChange() {
                if (this.busName === 'boll') {
                    this.getData('guestChange')
                }
            },
            eventChange(value) {
                this.getData('eventChange')
            },
            trackChange(value) {
                if (this.busName === 'boll') {
                    this.getData('trackChange')
                }
            },
            actChange(value) {
                if (this.busName === 'boll') {
                    this.getData('actChange')
                }
            },
            createChooseArr() {
                var arr = []
                for (var d of this.days) {
                    var children = []
                    for (var d1 of this.days) {
                        if (d1 > d) {
                            children.push({text: d1 + this.unit + '线', value: d1.toString()})
                        }
                    }
                    arr.push({
                        text: d + this.unit + '线',
                        value: d.toString(),
                        children: [{text: '金叉', value: '1', children: children}, {
                            text: '死叉',
                            value: '0',
                            children: children
                        }]
                    })
                }
                console.log(arr)
                return arr

            },
            accessChange(value) {
                this.masterNums = value[0]
                this.guest = value[2]
                this.eventDir = value[1]
                this.getData('accessChange')
            },
            changeSource(value) {
                if (value === '1') {
                    this.ifMaginTop = -40
                } else if (value === '2') {
                    this.ifMaginTop = -63
                } else {
                    this.ifMaginTop = -57
                }
                this.iback()
            },
            getIfSrc(isSelf) {
                var res
                if (isSelf) {
                    res = 'http://' + document.domain + ':' + window.location.port + '/bd?code=' + this.currentCode + '&market=' + this.currentMarket + '&detailSource=' + this.detailSource
                    return res
                }
                if (this.detailSource === '1') {
                    res = ('https://m.10jqka.com.cn/stockpage/hs_' + this.currentCode + '/#&atab=geguNews')
                } else if (this.detailSource === '2') {
                    res = ('https://wap.eastmoney.com/quote/stock/' + this.currentMarket + '.' + this.currentCode + '.html')
                } else {
                    res = ('https://xueqiu.com/S/' + (this.currentMarket === 1 ? 'SH' : 'SZ') + this.currentCode)
                }
                return res
            },
            titleClick() {
                this.urlShow = false
                this.currentCode = ''
                this.currentMarket = ''
                this.ifsrc = ''
            },
            iback() {
                if (this.ifsrc.includes(document.domain + ':' + window.location.port)) {
                    this.ifsrc = this.getIfSrc(false)
                } else {
                    this.ifsrc = this.getIfSrc(true)
                }
            },
            loadMore(done) {
                this.addData(done)
            },
            getTitleDay(day) {
                return day.toString().substr(0, 4) + '年' + day.toString().substr(4, 2) + '月' + day.toString().substr(6, 2) + '日'
            },

            addtionCode(code) {
                while (code.toString().length < 6) {
                    code = '0' + code
                }
                return code
            },
            getDataParams() {
                return {
                    dataType: this.dataType,
                    busType: this.busName + '_a',
                    date: this.dateChoose,
                    currentDate: this.day,
                    period: this.period,
                    master: this.preMaster + this.masterNums,
                    guest: this.preGuest + this.guest,
                    page: this.page,
                    event: this.eventDir
                };
            }
            ,
            addData(done) {
                Toast.loading('数据加载中', {
                    duration: 10000,
                    id: 'loading'
                });
                this.isLoading = true
                this.page += 1
                let p = this.getDataParams()
                axios({method: "post", url: "/acc/getAccessStock", params: p}).then(
                    (res) => {
                        this.isLoading = false
                        Toast.hide('loading')
                        if (res.data.code === 0) {
                            if (res.data.data.rows.length > 0) {
                                this.rows = this.rows.concat(res.data.data.rows)
                                this.day = parseInt(res.data.data.day)
                                this.today = parseInt(res.data.data.today)
                                this.total = parseInt(res.data.data.total)
                                this.currentDate = new Date(parseInt(this.day / 10000), parseInt((this.day % 10000) / 100), parseInt(this.day % 100))
                                this.maxDay = new Date(parseInt(this.today / 10000), parseInt((this.today % 10000) / 100) - 1, parseInt(this.today % 100))
                                if (this.busType === 'runUp' && this.busName !== 'boll') {
                                    this.title = this.getTitleDay(this.day) + '有' + this.total + '支股票站上' + this.guest + this.unit + '线'
                                }
                                if (this.busType === 'access') {
                                    this.title = this.getTitleDay(this.day) + '有' + this.total + '支股票' + this.masterNums + this.unit + '线' + (this.eventDir === '0' ? '死叉' : '金叉') + this.guest + this.unit + '线'
                                }
                                if (this.busType === 'single') {
                                    this.title = this.getTitleDay(this.day) + '有' + this.total + '支股票' + this.preMaster + this.masterNums + '金叉' + this.preGuest + this.guest
                                }
                                if (this.busType === 'double') {
                                    this.title = this.getTitleDay(this.day) + '有' + this.total + '支股票' + this.preMaster + this.masterNums + '金叉'
                                }
                                if (this.busType === 'non') {
                                    this.title = this.getTitleDay(this.day) + '有' + this.total + '支股票' + this.preMaster + this.masterNums + '金叉' + this.preGuest + this.guest
                                }
                                if (this.busName === 'boll') {
                                    this.title = this.getTitleDay(this.day) + '有' + this.total + '支股票' +
                                        (this.preMaster === 'close' ? '收盘价' : (this.preMaster === 'low' ? '最低价' : '最高价')) +
                                        (this.eventDir === '1' ? '突破' : '跌破') + '布林线' + (this.preGuest === 'mb_' ? '中轨' : (this.preGuest === 'up_' ? '上轨' : '下轨'))
                                }
                            } else {
                                this.hasMore = false
                            }
                            done()
                        } else if (res.data.code === 2) {
                            Toast.text(res.data.msg);
                            done()
                        } else {
                            Toast.text(res.data.msg);
                            done()
                        }
                    }
                );
            }
            ,
            getData(channel) {
                let p = this.getDataParams();
                console.log(channel)
                if (JSON.stringify(p) !== this.paramsTemp) {
                    this.paramsTemp = JSON.stringify(p)
                    console.log(JSON.stringify(p))
                    Toast.loading('数据加载中', {
                        duration: 10000,
                        id: 'loading'
                    });
                    this.accessChooseText = this.masterNums + this.unit + '线' + (this.eventDir === '0' ? '死叉' : '金叉') + this.guest + this.unit + '线'
                    this.isLoading = true
                    this.page = 1
                    this.hasMore = true
                    axios({method: "post", url: "/acc/getAccessStock", params: p}).then(
                        (res) => {
                            this.isLoading = false
                            Toast.hide('loading')
                            this.chooseV = []
                            if (res.data.code === 0) {
                                this.rows = res.data.data.rows
                                this.day = parseInt(res.data.data.day)
                                this.today = parseInt(res.data.data.today)
                                this.total = parseInt(res.data.data.total)
                                this.currentDate = new Date(parseInt(this.day / 10000), parseInt((this.day % 10000) / 100) - 1, parseInt(this.day % 100))
                                this.desc = parseInt(this.day / 10000) + '年' + parseInt((this.day % 10000) / 100) + '月' + parseInt(this.day % 100) + '日'
                                this.maxDay = new Date(parseInt(this.today / 10000), parseInt((this.today % 10000) / 100) - 1, parseInt(this.today % 100))
                                if (this.busType === 'runUp') {
                                    this.title = this.getTitleDay(this.day) + '有' + this.total + '支股票站上' + this.guest + this.unit + '线'
                                }
                                if (this.busType === 'access') {
                                    this.title = this.getTitleDay(this.day) + '有' + this.total + '支股票' + this.masterNums + this.unit + '线' + (this.eventDir === '0' ? '死叉' : '金叉') + this.guest + this.unit + '线'
                                }
                                if (this.busType === 'single') {
                                    this.title = this.getTitleDay(this.day) + '有' + this.total + '支股票' + this.preMaster + this.masterNums + '金叉' + this.preGuest + this.guest
                                }
                                if (this.busType === 'non') {
                                    this.title = this.getTitleDay(this.day) + '有' + this.total + '支股票' + this.preMaster + this.masterNums + '金叉' + this.preGuest + this.guest
                                }
                                if (this.busType === 'double') {
                                    this.title = this.getTitleDay(this.day) + '有' + this.total + '支股票' + this.preMaster + this.masterNums + '金叉'
                                }
                                if (this.busName === 'boll') {
                                    this.title = this.getTitleDay(this.day) + '有' + this.total + '支股票' +
                                        (this.preMaster === 'close' ? '收盘价' : (this.preMaster === 'low' ? '最低价' : '最高价')) +
                                        (this.eventDir === '1' ? '突破' : '跌破') + '布林线' + (this.preGuest === 'mb_' ? '中轨' : (this.preGuest === 'up_' ? '上轨' : '下轨'))
                                }
                                Toast.text(this.title);
                            } else if (res.data.code === 2) {
                                Toast.text(res.data.msg);
                                this.$router.push({path: "/phoneLogin"});
                            } else {
                                Toast.text(res.data.msg);
                            }
                        }
                    );
                }
            },

            toDetail(code, market) {
                this.currentCode = this.addtionCode(code)
                this.currentMarket = market
                this.ifsrc = this.getIfSrc(false)
                this.urlShow = true

            },
            clickName(value) {
                this.$copyText(value).then(function () {
                    Toast.text('股票代码' + value + '成功复制到剪贴板')
                })
            },
            back() {
                this.$router.push({path: "/yz"});
            },
            paramChange() {
                if (this.busType === 'single') {
                    this.masterNums = this.guest
                    this.getData('paramChange')
                }
            }
            ,
            numsChange(value) {
                if (this.busName === 'avg' && this.busType === 'runUp') {
                    this.getData('numsChange')
                }
            },
            unitChange(value) {
                if (value === '1') {
                    this.unit = '日'
                } else if (value === '2') {
                    this.unit = '周'
                } else {
                    this.unit = '月'
                }
                this.chooseOptions = this.createChooseArr()
                this.getData('unitChange')
            }
            ,
            typeChange(value) {
                this.getData('typeChange')
            },

            dateChange(value) {
                this.chooseShow = value === '3';
                if (!this.chooseShow) {
                    this.getData('dateChange')
                }
            },
            confirm({selectedValue, selectedOptions}) {
                this.desc = selectedOptions.map((option) => option.text).join('');
                this.day = parseInt(selectedValue.toString().replaceAll(',', ''))
                this.getData('confirm')
            }
        },
        data() {
            return {
                page: 1,
                period: 1,
                nums: 5,
                maxDay: new Date(),
                dateChoose: 1,
                chooseShow: false,
                ndShow: false,
                hasMore: true,
                day: '',
                today: '',
                days: [],
                desc: '',
                unit: '日',
                columns: [],
                currentDate: '',
                title: '',
                rows: [],
                total: 0,
                h: 0,
                urlShow: false,
                ifsrc: '',
                iframeHeight: document.documentElement.clientHeight,
                currentCode: '',
                currentMarket: '',
                detailSource: '1',
                ifMaginTop: -40,
                isLoading: false,
                showMaster: false,
                masterNums: 1,
                guest: 5,
                accessChooseText: '',
                chooseVisible: false,
                eventDir: '1',
                chooseV: [],
                chooseOptions: [],
                preMaster: '',
                preGuest: '',
                paramsTemp: '',
                doubleTemp: [],
                dataType: 'stocks'
            }
        }
    }
</script>

<style scoped>
    .flex-content {
        line-height: 40px;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        background: #ff8881;
    }

    .infiniteUl {
        height: 300px;
        width: 100%;
        padding: 0;
        margin: 0;
        overflow-y: auto;
        overflow-x: hidden;
        background-color: #FFF2E2;

    }

    .infiniteLi {
        margin-top: 10px;
        font-size: 14px;
        color: rgba(100, 100, 100, 1);
        text-align: center;
    }
</style>
