<template>

    <div style="height: auto;background-color: #FFF2E2;" ref="element">
        <nut-navbar @on-click-back="back" left-show style="margin-bottom: 5px"
                    :titIcon="require('../../background/icons/'+this.$route.params.img)" :title="this.$route.params.t">
            <template #left>
                <nut-cell title="返回" @click="back"></nut-cell>
            </template>
        </nut-navbar>
        <nut-form style="margin-top: -10px">
            <nut-form-item required label-align="center" style="padding: 10px" label-width="18%" label="日期:">
                <nut-radiogroup @change="dateChange" v-model="dateChoose" direction="horizontal">
                    <nut-radio :disabled="isLoading" label="1">最近交易{{unit}}</nut-radio>
                    <nut-radio :disabled="isLoading" label="2">前一{{unit}}</nut-radio>
                    <nut-radio :disabled="isLoading" label="3">选择</nut-radio>
                    <div v-show="chooseShow">
                        <nut-cell title="日期:" style="width:100%" :desc="desc" @click="ndShow = true"></nut-cell>
                        <nut-datepicker
                                v-model="currentDate"
                                v-model:visible="ndShow"
                                :min-date="new Date(1992, 0, 1)"
                                :max-date="maxDay"
                                :is-show-chinese="true"
                                @confirm="confirm"
                        ></nut-datepicker>
                    </div>
                </nut-radiogroup>
            </nut-form-item>
        </nut-form>
    </div>
    <nut-row>
        <nut-col>
            <div class="flex-content" @click="this.divClick(c[0])">日期:{{day}},{{title}}</div>
        </nut-col>
    </nut-row>
    <ul class="infiniteUl" id="scroll" :style="'height: '+this.h+'px'">
        <nut-infiniteloading
                containerId='scroll'
                :use-window='false'
                :has-more="hasMore"
                @load-more="loadMore"
        >
            <nut-cell v-for="r in this.commonRows"
                      :key="r['code']"
                      title=""
                      sub-title=""
                      class="infiniteLi "
                      style=" text-align: left;background-color: #FDE6E0;">
                <template v-slot:icon>
                    <div>
                        <div style="display: inline-block;font-size: large">

                        </div>
                        <div @click="clickName(r['code'])"
                             style="display: inline-block;width:25%;position: absolute; color: #f05b72;background-color: #FDE6E0;">
                            <div>
                                <nut-tag style="font-size: large;color: #0096e6" plain>{{r['name']}}</nut-tag>
                            </div>
                            <div style="font-size: large">
                                {{this.addtionCode(r['code'])}}
                            </div>
                        </div>
                    </div>
                </template>
                <template v-slot:link>
                    <div style="margin-left: 30%;color: #0096e6;background-color: #FDE6E0;">
                        <div @click="clickName(r['code'])"
                             style="float: left;width: 60%">
                            {{r['code']}}值{{r['code']}}两市排名{{parseInt(r['code'])===0?'最低':'最高'}}第{{r['code']}}
                        </div>

                        <div style="width: 35%; margin-left: 5%;float: right;">

                            <nut-button @click="toDetail(r['code'],r['name'])"
                                        style="margin-right: 1%;float: right"
                                        color="#7232dd"
                                        plain>
                                详情
                            </nut-button>
                            <nut-button @click="toQuotation(r['code'],r['name'])"
                                        style="margin-right: 1%;float: right"
                                        color="#7232dd"
                                        plain>
                                行情
                            </nut-button>
                        </div>
                    </div>
                </template>
            </nut-cell>
            <nut-backtop></nut-backtop>
        </nut-infiniteloading>
    </ul>
<!--    <div>-->
<!--        {{this.$route.params.type}}-->
<!--    </div>-->
<!--    <div>-->
<!--        {{this.$route.params.id}}-->
<!--    </div>-->
</template>

<script>
    import {Toast} from '@nutui/nutui';
    import axios from "axios";
    import {ref} from 'vue';
    export default {
        name: "",
        data() {
            return {
                dateChoose: 1,
                isLoading: false,
                unit: '日',
                h: 0,
                currentDate: '',
                iframeHeight: document.documentElement.clientHeight,
                desc: '',
                day: '',
                ndShow: false,
                maxDay: new Date(),
                chooseShow: false,
                commonRows: [],
                title: '111',
            }
        },
        mounted(){
            this.h = document.documentElement.clientHeight - this.$refs.element.offsetHeight
        }
        ,
        methods: {
            toDetail(code,name){},
            toQuotation(code,name){},
            clickName(code){},
            dateChange(value) {
                this.chooseShow = value === '3';
                if (!this.chooseShow) {
                    this.getData('dateChange')
                }
            },
            addtionCode(code) {
                while (code.toString().length < 6) {
                    code = '0' + code
                }
                return code
            },
            back() {
                this.$router.push({path: "/dyz"});
            },
            confirm({selectedValue, selectedOptions}) {
                this.desc = selectedOptions.map((option) => option.text).join('');
                this.day = parseInt(selectedValue.toString().replaceAll(',', ''))
                this.getData('confirm')
            },

            getData(channel) {
                console.log(channel)
                let p = {
                    mid:this.$route.params.id,
                    date: this.dateChoose,
                    currentDate: this.day
                }

                axios({method: "post", url: "/dyz/getDYZData", params:p}).then(
                    (res) => {
                        Toast.hide('loading')
                        this.chooseV = []
                        if (res.data.code === 0) {
                            console.log(res.data)
                            this.title=res.data.data.title
                            this.commonRows = res.data.data.codes
                        } else {
                            Toast.text(res.data.msg);
                        }
                    }
                );
            }
        }
    }
</script>

<style scoped>
    .flex-content {
        line-height: 40px;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        background: #ff8881;
    }

    .infiniteUl {
        height: 300px;
        width: 100%;
        padding: 0;
        margin: 0;
        overflow-y: auto;
        overflow-x: hidden;
        background-color: #FFF2E2;

    }

    .infiniteLi {
        margin-top: 10px;
        font-size: 14px;
        color: rgba(100, 100, 100, 1);
        text-align: center;
    }
</style>
