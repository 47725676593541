<template>
    <AccessQury v-if="['access','runUp','single','non','double'].indexOf(this.$route.params.busType)>-1"
                style="height: 100%;width: 100%" :busType="this.$route.params.busType"
                :busName="this.$route.params.busName" :tIcon="this.$route.params.img" :t="this.$route.params.t">
    </AccessQury>
    <RankingQuery v-if="['ranking','ranking2'].indexOf(this.$route.params.busType)>-1"
                  style="height: 100%;width: 100%" :busType="this.$route.params.busType"
                  :busName="this.$route.params.busName" :tIcon="this.$route.params.img" :t="this.$route.params.t">

    </RankingQuery >
    <TrendQuery v-if="['trend','trend2'].indexOf(this.$route.params.busType)>-1"
                style="height: 100%;width: 100%" :busType="this.$route.params.busType"
                :busName="this.$route.params.busName" :tIcon="this.$route.params.img" :t="this.$route.params.t"
    >
    </TrendQuery>
</template>

<script>
    import AccessQury from "../../components/AccessQuery"
    import RankingQuery from "../../components/RankingQuery"
    import TrendQuery from "../../components/TrendQuery"

    export default {
        name: "",
        components: {AccessQury,RankingQuery,TrendQuery},
        data() {
            return {}
        }
    }
</script>

<style scoped>
</style>
