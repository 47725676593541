<template>
    <!--    <CanvasBackground>-->
    <div class="video-container">
        <div class="filter">
            <h1 class="title-login">灵芯后台注册</h1>
            <nut-form @submit.native.prevent>
                <nut-form-item>
                    <nut-input
                            type="text"
                            class="input-login"
                            placeholder="请输入用户名"
                            v-model="username"
                    ></nut-input>
                </nut-form-item>
                <nut-form-item>
                    <nut-input
                            class="input-login"
                            placeholder="请输入密码"
                            show-password
                            v-model="password"
                    ></nut-input>
                </nut-form-item>
                <nut-form-item>
                    <nut-input
                            class="input-login"
                            placeholder="请再次输入密码"
                            show-password
                            v-model="password1"
                    ></nut-input>
                </nut-form-item>
                <nut-form-item>
                    <nut-input
                            type="text"
                            class="input-login"
                            placeholder="请输入手机号"
                            v-model="phone"
                    ></nut-input>
                </nut-form-item>
                <nut-form-item>
                    <div style="float: initial">
                        <nut-input class="input-login" style="width: 40%" placeholder="验证码" v-model="auth">

                        </nut-input>
                        <nut-button slot="suffix" @click="getVerifyCode" style="width: 20%" type="primary"
                                    :disabled="disable"
                                    class="{codeGeting:isGeting}">
                            {{getCode}}
                        </nut-button>
                    </div>
                </nut-form-item>
                <nut-form-item>
                    <nut-button class="btn-login" type="primary" @click.native="handleClick"
                    >注册
                    </nut-button>
                </nut-form-item>
            </nut-form>
        </div>
    </div>
    <!--    </CanvasBackground>-->
</template>

<script>
    // import CanvasBackground from "../../components/CanvasBackground";
    import axios from "axios";

    export default {
        name: "",
        components: {
            // CanvasBackground,
        },
        data() {
            return {
                getCode: '获取验证码',
                isGeting: false,
                count: 6,
                disable: false,
                username: "",
                password: "",
                password1: "",
                phone: "",
                auth: ""
                // ,birthday: "",
                // remark: "",
                // name: ""
            }
        },
        methods: {
            handleClick: function () {
                var vm = this
                if (this.password !== this.password1) {
                    vm.$message.error("两次输入密码不一致,请检查密码");
                    return
                }
                if (!this.phone) {
                    vm.$message.error("请先填写手机号");
                    return
                }

                if (!this.username) {
                    vm.$message.error("用户名不能为空");
                    return
                }
                if (!this.auth) {
                    vm.$message.error("验证码不能为空");
                    return
                }
                if (!this.password) {
                    vm.$message.error("密码不能为空");
                    return
                }
                let p = {
                    userName: this.username,
                    // name: this.name,
                    password: this.password,
                    phone: this.phone,
                    auth: this.auth,
                    // birthday: this.birthday,
                    // remark: this.remark
                };

                axios({method: 'post', url: '/regist/registAdmin', params: p})
                    .then(res => {
                        if (res.data.code == 0) {
                            vm.$message({
                                message: '注册成功',
                                type: 'success'
                            });
                            vm.$router.push({path: '/pclogin'})
                        } else {
                            vm.$message.error(res.data.msg);
                        }
                    })
            },
            getVerifyCode() {
                var vm = this
                if (!this.phone) {
                    vm.$message.error("请先填写手机号");
                    return
                }
                this.disable = true
                this.getCode = this.count-- + 's后重发';

                let p = {
                    phone: this.phone,
                    globalRoaming: '+86'
                }
                axios({method: 'post', url: '/regist/sendAuth', params: p})
                    .then(res => {
                        if (res.data.code == 0) {
                            vm.$message({
                                message: '短信验证码发送成功',
                                type: 'success'
                            });
                        } else {
                            vm.$message.error(res.data.msg);
                        }
                    })
                var countDown = setInterval(() => {
                    if (this.count < 1) {
                        this.isGeting = false;
                        this.disable = false;
                        this.getCode = '重新获取';
                        this.count = 6;
                        clearInterval(countDown);
                    } else {
                        this.isGeting = true;
                        this.disable = true;
                        this.getCode = this.count-- + 's后重发';
                    }
                }, 1000);

            }
        }
    }
</script>

<style scoped>
    .title-login {
        text-align: center;
        color: #fff;
        font-size: 24px;
        margin-bottom: 20px;
    }

    .input-login {
        width: 60%;
    }

    .video-container .filter {
        z-index: 1;
        position: absolute;
        background: rgba(0, 0, 0, 0.3);
        width: 30%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .btn-login {
        width: 60%;
    }

    .input-login {
        width: 60%;
        font-size: medium;
    }

    .codeGeting {
        background: #cdcdcd;
        border-color: #cdcdcd;
    }
</style>
