<template>
    <div >
        <nut-tabs v-model="selected">
            <template v-slot:titles>
                <div class="head-content">{{titleMapping[selected]}}</div>
            </template>
            <nut-tabpane :pane-key=0 >
                <XuanGuMain>

                </XuanGuMain>
            </nut-tabpane>
            <nut-tabpane pane-key=1>
                <WenGuMain>

                </WenGuMain>
            </nut-tabpane>
            <nut-tabpane pane-key=2>
                <KanKanMain>

                </KanKanMain>
            </nut-tabpane>
        </nut-tabs>

        <nut-tabbar :bottom="true" v-model:visible="selected"
                    :safeAreaInsetBottom="true" size="30px">
            <nut-tabbar-item font-class-name="f"
                             tab-title="选股"
                             :img="require('../../../src/background/icons/stacked_bar_chart.svg')"
                             :activeImg="require('../../../src/background/icons/stacked_bar_chart.svg')"

            >

            </nut-tabbar-item>
            <nut-tabbar-item
                    tab-title="问股"
                    :img="require('../../../src/background/icons/Heatmap.svg')"
                    :activeImg="require('../../../src/background/icons/Heatmap.svg')"
            >

            </nut-tabbar-item>
            <nut-tabbar-item
                    tab-title="看看"
                    :img="require('../../../src/background/icons/treemap_chart_colored.svg')"
                    :activeImg="require('../../../src/background/icons/treemap_chart_colored.svg')"
            >
            </nut-tabbar-item>
        </nut-tabbar>
        <!--        -->
        <!--        <mt-tabbar v-model="selected" fixed class="tbar">-->
        <!--            <mt-tab-item id="tab1" class="tb">-->
        <!--                <img class="tb" slot="icon" src="../../../src/background/icons/stacked_bar_chart.svg">选股-->
        <!--            </mt-tab-item>-->
        <!--            <mt-tab-item id="tab2">-->
        <!--                <img slot="icon" src="../../../src/background/icons/line_chart_against.svg"> 问股-->
        <!--            </mt-tab-item>-->
        <!--            <mt-tab-item id="tab3">-->
        <!--                <img slot="icon" src="../../../src/background/icons/line_chart_dots.svg"> 看看-->
        <!--            </mt-tab-item>-->
        <!--            <mt-tab-item id="tab4">-->
        <!--                <img slot="icon" src="../../../src/background/icons/scatterplot_sized_colored.svg"> 我-->
        <!--            </mt-tab-item>-->
        <!--        </mt-tabbar>-->
    </div>
</template>

<script>
    import XuanGuMain from "../../components/XuanGuMain";
    import WenGuMain from "../../components/WenGuMain";
    import KanKanMain from "../../components/KanKanMain";

    export default {
        components: {XuanGuMain,WenGuMain,KanKanMain},
        data() {
            return {
                selected: 0,
                titleMapping: {'0': '选股', '1': '问股', '2': '看看'}
            };
        },
        methods: {
            fileManage() {
                if (this.contentTabs.indexOf('file_manage') <= -1) {
                    this.contentTabs.push("file_manage")
                }
                this.activeContent = "file_manage"
            },
            xinshiContent() {
                if (this.contentTabs.indexOf('xinshi_content') <= -1) {
                    this.contentTabs.push("xinshi_content")
                }
                this.activeContent = "xinshi_content"
            },
            userSug() {
                if (this.contentTabs.indexOf('user_sug') <= -1) {
                    this.contentTabs.push("user_sug")
                }
                this.activeContent = "user_sug"
            },
            userList() {
                if (this.contentTabs.indexOf('user_list') <= -1) {
                    this.contentTabs.push("user_list")
                }
                this.activeContent = "user_list"
            },

            mounted() {

            }
            ,
            cece() {
                alert("功能尚未完善");
            },
            editKefu() {
                if (this.contentTabs.indexOf('kefu') <= -1) {
                    this.contentTabs.push("kefu")
                }
                this.activeContent = "kefu"
            },
            ceceContent() {
                if (this.contentTabs.indexOf('cece_content') <= -1) {
                    this.contentTabs.push("cece_content")
                }
                this.activeContent = "cece_content"
            },

            removeTab(targetName) {
                let tabs = this.contentTabs;
                let activeName = this.editableTabsValue;
                if (activeName === targetName) {
                    tabs.forEach((tab, index) => {
                        if (tab.name === targetName) {
                            let nextTab = tabs[index + 1] || tabs[index - 1];
                            if (nextTab) {
                                activeName = nextTab.name;
                            }
                        }
                    });
                }
                this.editableTabsValue = activeName;
                this.contentTabs = tabs.filter((tab) => tab.name !== targetName);
            },
        },
    };
</script>

<style scoped>
    .head-content {
        line-height: 40px;
        color: white;
        font-weight:600;
        text-align: center;
        background: #f05b72;
        background-size: 1000px;
        width: 100%;
        font-size: x-large;
    }

</style>
