<template>
    <div class="container bg">
        <div class="content">
            <div id="large-header" class="large-header">
                <canvas id="bg-canvas" v-on:mousemove="mouseMove"></canvas>
            </div>
        </div>
    </div>
</template>

<script>
    import TweenLite from 'gsap'

    export default {
        name: "aaa",
        data() {
            return {
                width: 0,
                height: 0,
                target: 0,
                largeHeader: {},
                canvas: {},
                ctx: {},
                points: [],
                animateHeader: {},
            }
        },
        mounted() {
            this.initHeader();
            this.initAnimation();
            this.addListeners();
        },
        methods: {
            initHeader() {
                this.width = window.innerWidth;
                this.height = window.innerHeight;
                this.target = {x: this.width / 2, y: this.height / 2};
                this.largeHeader = document.getElementById('large-header');
                this.largeHeader.style.height = this.height + 'px';
                this.canvas = document.getElementById('bg-canvas');
                this.canvas.width = this.width;
                this.canvas.height = this.height;
                this.ctx = this.canvas.getContext('2d');

                // create points
                this.points = [];
                for (var x = 0; x < this.width; x = x + this.width / 20) {
                    for (var y = 0; y < this.height; y = y + this.height / 20) {
                        var px = x + Math.random() * this.width / 20;
                        var py = y + Math.random() * this.height / 20;
                        var p = {x: px, originX: px, y: py, originY: py};
                        this.points.push(p);
                    }
                }

                // for each point find the 5 closest points
                for (var i = 0; i < this.points.length; i++) {
                    var closest = [];
                    var p1 = this.points[i];
                    for (var j = 0; j < this.points.length; j++) {
                        var p2 = this.points[j]
                        if (!(p1 === p2)) {
                            var placed = false;
                            for (var k = 0; k < 5; k++) {
                                if (!placed) {
                                    if (closest[k] === undefined) {
                                        closest[k] = p2;
                                        placed = true;
                                    }
                                }
                            }

                            for (var a = 0; a < 5; a++) {
                                if (!placed) {
                                    if (this.getDistance(p1, p2) < this.getDistance(p1, closest[a])) {
                                        closest[a] = p2;
                                        placed = true;
                                    }
                                }
                            }
                        }
                    }
                    p1.closest = closest;
                }

                // assign a circle to each point
                for (var t in this.points) {
                    var c = new this.Circle(this.points[t], 2 + Math.random() * 2, 'rgba(255,255,255,0.3)', this.ctx);
                    this.points[t].circle = c;
                }
            },

            // Event handling
            addListeners() {
                if (!('ontouchstart' in window)) {
                    window.addEventListener('mousemove', this.mouseMove);
                }
                window.addEventListener('scroll', this.scrollCheck);
                window.addEventListener('resize', this.resize);
            },

            mouseMove(e) {
                var posx = 0
                var posy = 0;
                if (e.pageX || e.pageY) {
                    posx = e.pageX;
                    posy = e.pageY;
                } else if (e.clientX || e.clientY) {
                    posx = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
                    posy = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
                }
                this.target.x = posx;
                this.target.y = posy;
            },

            scrollCheck() {
                if (document.body.scrollTop > this.height) this.animateHeader = false;
                else this.animateHeader = true;
            }
            ,
            resize() {
                this.width = window.innerWidth;
                this.height = window.innerHeight;
                this.largeHeader.style.height = this.height + 'px';
                this.canvas.width = this.width;
                this.canvas.height = this.height;
            }
            ,
            // animation
            initAnimation() {
                this.animate();
                for (var i in this.points) {
                    this.shiftPoint(this.points[i]);
                }
            }
            ,
            animate() {
                if (this.animateHeader) {
                    this.ctx.clearRect(0, 0, this.width, this.height);
                    for (var i in this.points) {
                        // detect points in range
                        if (Math.abs(this.getDistance(this.target, this.points[i])) < 4000) {
                            this.points[i].active = 0.3;
                            this.points[i].circle.active = 0.6;
                        } else if (Math.abs(this.getDistance(this.target, this.points[i])) < 20000) {
                            this.points[i].active = 0.1;
                            this.points[i].circle.active = 0.3;
                        } else if (Math.abs(this.getDistance(this.target, this.points[i])) < 40000) {
                            this.points[i].active = 0.02;
                            this.points[i].circle.active = 0.1;
                        } else {
                            this.points[i].active = 0;
                            this.points[i].circle.active = 0;
                        }

                        this.drawLines(this.points[i]);
                        this.points[i].circle.draw();
                    }
                }
                requestAnimationFrame(this.animate);
            },

            shiftPoint(p) {
                var _this = this
                TweenLite.to(p, 1 + 1 * Math.random(), {
                    x: p.originX - 50 + Math.random() * 100,
                    y: p.originY - 50 + Math.random() * 100,
                    ease: {},
                    onComplete: function () {
                        _this.shiftPoint(p)
                    }
                });
            },

            // Canvas manipulation
            drawLines(p) {
                if (!p.active) return;
                for (var i in p.closest) {
                    this.ctx.beginPath();
                    this.ctx.moveTo(p.x, p.y);
                    this.ctx.lineTo(p.closest[i].x, p.closest[i].y);
                    this.ctx.strokeStyle = 'rgba(156,217,249,' + p.active + ')';
                    this.ctx.stroke();
                }
            },

            Circle(pos, rad, color, ctx) {
                var _this = this;

                // constructor
                (function () {
                    _this.pos = pos || null;
                    _this.radius = rad || null;
                    _this.color = color || null;
                })();

                this.draw = function () {
                    if (!_this.active) return;
                    ctx.beginPath();
                    ctx.arc(_this.pos.x, _this.pos.y, _this.radius, 0, 2 * Math.PI, false);
                    ctx.fillStyle = 'rgba(156,217,249,' + _this.active + ')';
                    ctx.fill();
                };
            },

            // Util
            getDistance(p1, p2) {
                return Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2);
            }
        }
    }

</script>

<style scoped>


    p.ref {
        text-align: center;
        padding: 2em 1em;
    }

    /* Header */
    .large-header {
        position: relative;
        width: 100%;
        background: #333;
        overflow: hidden;
        background-size: cover;
        background-position: center center;
        z-index: 1;
    }

    .bg .large-header {
        background-image: url('../background/img/bg.jpg');
        z-index: 0;
    }



    article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
        display: block;
    }

    audio, canvas, video {
        display: inline-block;
    }

    audio:not([controls]) {
        display: none;
        height: 0;
    }

    [hidden] {
        display: none;
    }

    html {
        font-family: sans-serif;
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
    }

    body {
        margin: 0;
    }

    a:focus {
        outline: thin dotted;
    }

    a:active, a:hover {
        outline: 0;
    }

    h1 {
        font-size: 2em;
        margin: 0.67em 0;
    }

    abbr[title] {
        border-bottom: 1px dotted;
    }

    b, strong {
        font-weight: bold;
    }

    dfn {
        font-style: italic;
    }

    hr {
        -moz-box-sizing: content-box;
        box-sizing: content-box;
        height: 0;
    }

    mark {
        background: #ff0;
        color: #000;
    }

    code, kbd, pre, samp {
        font-family: monospace, serif;
        font-size: 1em;
    }

    pre {
        white-space: pre-wrap;
    }

    q {
        quotes: "\201C" "\201D" "\2018" "\2019";
    }

    small {
        font-size: 80%;
    }

    sub, sup {
        font-size: 75%;
        line-height: 0;
        position: relative;
        vertical-align: baseline;
    }

    sup {
        top: -0.5em;
    }

    sub {
        bottom: -0.25em;
    }

    img {
        border: 0;
    }

    svg:not(:root) {
        overflow: hidden;
    }

    figure {
        margin: 0;
    }

    fieldset {
        border: 1px solid #c0c0c0;
        margin: 0 2px;
        padding: 0.35em 0.625em 0.75em;
    }

    legend {
        border: 0;
        padding: 0;
    }

    button, input, select, textarea {
        font-family: inherit;
        font-size: 100%;
        margin: 0;
    }

    button, input {
        line-height: normal;
    }

    button, select {
        text-transform: none;
    }

    button, html input[type="button"], input[type="reset"], input[type="submit"] {
        -webkit-appearance: button;
        cursor: pointer;
    }

    button[disabled], html input[disabled] {
        cursor: default;
    }

    input[type="checkbox"], input[type="radio"] {
        box-sizing: border-box;
        padding: 0;
    }

    input[type="search"] {
        -webkit-appearance: textfield;
        -moz-box-sizing: content-box;
        -webkit-box-sizing: content-box;
        box-sizing: content-box;
    }

    input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
        -webkit-appearance: none;
    }

    button::-moz-focus-inner, input::-moz-focus-inner {
        border: 0;
        padding: 0;
    }

    textarea {
        overflow: auto;
        vertical-align: top;
    }

    table {
        border-collapse: collapse;
        border-spacing: 0;
    }

    .video-container .filter {
        z-index: 1;
        position: absolute;
        background: rgba(0, 0, 0, 0.3);
        width: 30%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
</style>
