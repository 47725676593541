<template>
    <div>
        <div style="margin-top: 45px">
            <div v-for="c in dyzMapping">
                <nut-row>
                    <nut-col>
                        <div class="flex-content" @click="this.divClick(c[0])">{{c[1]}}</div>
                    </nut-col>
                </nut-row>
                <nut-row>
                    <nut-col :span="24">
                        <nut-grid :column-num="3">
                            <nut-grid-item v-for="r in commonMapping[c[0]]"
                                           :icon="require('../../background/icons/'+r['headUrl'])" :text="r['name']"
                                           :to="{
                            name:'dyzdetail',
                            params:{
                                img:r['headUrl'],
                                t:r['name'],
                                type:c[0],
                                id:r['id']
                            }
                        }"></nut-grid-item>
                        </nut-grid>
                    </nut-col>
                </nut-row>
                <nut-divider/>
            </div>
        </div>
        <nut-navbar @on-click-back="back" left-show fixed ref="nbar"
                    :titIcon="require('../../background/icons/line_chart_parallel.svg')" title="多因子选股">
            <template #left>
                <nut-cell title="返回" @click="back"></nut-cell>
            </template>
        </nut-navbar>
        <nut-backtop></nut-backtop>
    </div>
</template>

<script>
    import allMapping from '../../const/introduceMapping'
    import axios from "axios";
    import {ref} from 'vue';
    import {Toast} from '@nutui/nutui';

    export default {
        data() {
            return {
                ovShow: false,
                checked: true,
                ovT: '',
                ovF: '',
                ovC: [],
                ovI: '',
                commonMapping: {},
                dyzMapping: {}
            }
        }
        ,
        mounted() {
            this.getDyzCommon()
            this.dyzMapping = allMapping['dyzMapping']
        }
        ,
        methods: {
            back() {
                this.$router.push({path: "/pcMain"});
            },
            getDyzCommon() {
                Toast.loading('数据加载中', {
                    duration: 10000,
                    id: 'loading'
                });
                axios({method: "post", url: "/dyz/initCommonDyz"}).then(
                    (res) => {
                        Toast.hide('loading')
                        this.chooseV = []
                        if (res.data.code === 0) {
                            console.log(res.data)
                            this.commonMapping['common'] = res.data.data
                        } else {
                            Toast.text(res.data.msg);
                        }
                    }
                );
            }
        }
    };
</script>
<style lang="scss" scoped>
    .flex-content {
        line-height: 40px;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        background: #ff8881;
    }

    .nut-row {
        overflow: hidden;

        &:not(:last-child) .nut-col {
            margin-bottom: 15px;
        }

        margin-bottom: 15px;
    }

    .wrapper {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;

        .content {
            display: flex;
            width: 150px;
            height: 150px;
            background: #fff;
            border-radius: 8px;
            align-items: center;
            justify-content: center;
            color: red;
        }
    }

    .ct {
        display: flex;
    }

    .rt {
        margin-left: 10px;
        display: flex;
        flex-direction: column;
    }

    .rc {
        margin-left: 12px;
        display: flex;
        flex-direction: column;
    }

    .na {
        margin-left: 15px;
        margin-top: 15px;
    }

    .t {
        margin-top: 15px;
        font-size: 26px;
        color: white;
    }

    .desc {
        margin-top: 5px;
        font-size: 18px;
        color: #e9d0a7;
        text-align: left;
    }
</style>
