import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import axios from "axios";
import allMapping from './const/introduceMapping'
import VueClipboard from 'vue-clipboard2'

import {
    Button, Tabbar, TabbarItem, Icon, Video, Input, Form, Grid, GridItem,
    FormItem, Cell, CellGroup, Notify, Tabs, TabPane, Layout, Row, Col,
    Navbar, Divider, OverLay, Skeleton, Avatar, Switch, BackTop, Radio, RadioGroup,
    DatePicker, Picker, Popup, InfiniteLoading, Empty, Cascader, Tag, Toast
} from "@nutui/nutui";
createApp(App).use(VueClipboard).use(allMapping).use(router).use(Button).use(Empty).use(Cascader)
    .use(InfiniteLoading).use(DatePicker).use(Picker).use(Popup).use(Radio).use(RadioGroup).use(BackTop)
    .use(Switch).use(Avatar).use(Tabbar).use(Tag).use(TabbarItem).use(Notify).use(Icon).use(Video).use(Input).use(Form)
    .use(FormItem).use(Cell).use(Col).use(Row).use(Tabs).use(Layout).use(TabPane).use(CellGroup).use(GridItem).use(Grid).use(Divider).use(Navbar).use(OverLay).use(Skeleton).mount("#app");



axios.defaults.withCredentials = true;
// window.baseURL = 'http://localhost:8088/';
window.baseURL = 'https://lingxin.vip/api/';
axios.defaults.timeout = 5000;
axios.defaults.headers.post["Content-Type"] = "application/json;charset=UTF-8";
// Vue.config.productionTip = false;
axios.defaults.baseURL = window.baseURL;

axios.interceptors.request.use(
    (config) => {
        // 如果Cookie中存在这个token，就在header中设置这个token
        // 这样token就可以跨域转发
        // localStorage.setItem("stockAdminAuth","eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxIiwiaWF0IjoxNjU2ODExNzA2LCJleHAiOjE2NTY4MTg5MDZ9.BaFoZMBAEPZJle3u_UWHbAY2q6R6PSl6NCt6sCNfyJGk-VzlNxuiOSQA0cYcGkZovpFAvhgJZLjtyr00whDa0Q")
        // localStorage.setItem("cKey", "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJ7XCJ0b2tlbk1kNVwiOlwiYzU1OWRkMDJiNWVjZTA5NTJhMzAxN2RiZmIzY2QxYzJcIixcImRhdGFNZDVcIjpcIjUwMmRjNThlMGJjM2VlMmFmZmFhZjkxNWMxMTQxYzk5XCJ9IiwiaWF0IjoxNjU2ODExNzE0LCJleHAiOjE2NTk0MDM3MTR9.RmZlPCoE4CIuOfvEQ4ZzL3NHyV5EuJxloGyIPgMrSF4QNBLqPB_t0SpveRxLEog4X5fGu5qhfvmiAi_M7bkVUw")
        config.headers["stockAdminAuth"] =
            localStorage.getItem("stockAdminAuth");
        config.headers["cid"] = VERSION
        // if(cookie.get('user_token')){
        //   config.headers['token'] = cookie.get('user_token');
        // }
        return config;
    },
    (err) => {
        // 出错调用
        return Promise.reject(err);
    }
);
axios.interceptors.response.use(
    async (response) => {
        // 过期重登陆

        if (response.data.code === 4) {
            let p = {
                token: localStorage.getItem("stockAdminAuth"),
                cKey: localStorage.getItem("cKey")
            }
            // token过期,重新发起请求
            const res = await axios({method: "post", url: "/login/renew", params: p})
            if (res.data.code === 0) {
                localStorage.setItem("stockAdminAuth", res.data.data.token)
                localStorage.setItem("cKey", res.data.data.cKey)
                response.config.headers["stockAdminAuth"] =
                    localStorage.getItem("stockAdminAuth");
                return axios(response.config);
            }
        }
        if (response.data.code <= 7 && response.data.code >= 5) {
            console.log("response.data.code in 5,6,7,code:" + response.data.code)
            Toast.text(response.data.msg);
            this.$router.push({path: "/phoneLogin"});
        }
        return response
    },
)

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})
