<template>
    <div ref="element1" style="background-color: #FFF2E2;">
        <div style="height: auto;background-color: #FFF2E2;" ref="element">
            <nut-navbar @on-click-back="back" left-show style="margin-bottom: 5px"
                        :titIcon="tIcon" :title="t">
                <template #left>
                    <nut-cell title="返回" @click="back"></nut-cell>
                </template>
            </nut-navbar>
            <nut-form style="margin-top: -10px">
                <nut-form-item required label-align="center" style="padding: 10px" label-width="18%" label="数据类型:">
                    <nut-radiogroup @change="typeChange" v-model="dataType" direction="horizontal">
                        <nut-radio :disabled="isLoading" label="stocks">个股</nut-radio>
                        <nut-radio :disabled="isLoading" label="indices">指数</nut-radio>
                    </nut-radiogroup>
                </nut-form-item>
                <nut-form-item required label-align="center" style="padding: 10px" label-width="18%" label="日期:">
                    <nut-radiogroup @change="dateChange" v-model="dateChoose" direction="horizontal">
                        <nut-radio :disabled="this.isLoading" label="1">最近交易{{unit}}</nut-radio>
                        <nut-radio :disabled="this.isLoading" label="2">前一{{unit}}</nut-radio>
                        <nut-radio :disabled="this.isLoading" label="3">选择</nut-radio>
                        <div v-show="chooseShow">
                            <nut-cell title="日期:" style="width:100%" :desc="desc" @click="ndShow = true"></nut-cell>
                            <nut-datepicker
                                    v-model="currentDate"
                                    v-model:visible="ndShow"
                                    :min-date="new Date(1992, 0, 1)"
                                    :max-date="maxDay"
                                    :is-show-chinese="true"
                                    @confirm="confirm"
                            ></nut-datepicker>
                        </div>
                    </nut-radiogroup>
                </nut-form-item>

                <nut-form-item required label-align="center" style="padding: 10px" label-width="18%" label="周期:">
                    <nut-radiogroup v-model="period" @change="unitChange" direction="horizontal">
                        <nut-radio :disabled="this.isLoading" label="1">日线</nut-radio>
                        <nut-radio style="margin-left: 15px" :disabled="this.isLoading"
                                   label="2">周线
                        </nut-radio>
                        <nut-radio style="margin-left: 15px" :disabled="this.isLoading" label="3">
                            月线
                        </nut-radio>
                    </nut-radiogroup>
                </nut-form-item>
                <nut-form-item required label-align="center" style="padding: 10px"
                               label-width="18%" label="顺序:">
                    <nut-radiogroup v-model="this.directionDir" @change="directionChange" direction="horizontal">
                        <nut-radio shape="button" label=0>最低
                        </nut-radio>
                        <nut-radio shape="button" label=1>最高
                        </nut-radio>
                    </nut-radiogroup>
                </nut-form-item>
                <nut-form-item v-if="this.preList.length>1" required label-align="center" style="padding: 10px"
                               label-width="18%" label="指数:">
                    <nut-radiogroup v-model="this.preMaster" @change="indexChange" direction="horizontal">
                        <nut-radio :disabled="this.isLoading" v-for="p in this.preList" shape="button" :label="p">
                            {{p}}
                        </nut-radio>
                    </nut-radiogroup>
                </nut-form-item>

                <nut-form-item required label-align="center"
                               style="padding: 10px"
                               label-width="18%" label="参数:">
                    <nut-radiogroup v-model="this.masterNums" @change="paramChange" direction="horizontal">
                        <nut-radio :disabled="this.isLoading" v-for="d in this.days" shape="button"
                                   :label="this.busType==='ranking'?d:(this.preList.indexOf(this.preMaster)===0?d[0]:d[1])">
                            {{this.busType==='ranking'?d:d[0]}}
                        </nut-radio>
                    </nut-radiogroup>
                </nut-form-item>

            </nut-form>
        </div>
        <nut-row>
            <nut-col>
                <div class="flex-content" @click="this.divClick(c[0])">{{title}}</div>
            </nut-col>
        </nut-row>
        <ul class="infiniteUl" id="scroll" :style="'height: '+this.h+'px'">
            <nut-infiniteloading
                    containerId='scroll'
                    :use-window='false'
                    :has-more="hasMore"
                    @load-more="loadMore"
            >
                <nut-cell v-for="(r,index) in rows"
                          :key="index"
                          title=""
                          sub-title=""
                          class="infiniteLi "
                          style=" text-align: left;background-color: #FDE6E0;">
                    <template v-slot:icon>
                        <div>
                            <div style="display: inline-block;font-size: large">
                                {{index+1}}.
                            </div>
                            <div @click="clickName(r['c'])"
                                 style="display: inline-block;width:25%;position: absolute; color: #f05b72;background-color: #FDE6E0;">
                                <div>
                                    <nut-tag style="font-size: large;color: #0096e6" plain>{{r['name']}}</nut-tag>
                                </div>
                                <div style="font-size: large">
                                    {{this.addtionCode(r['c'])}}
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-slot:link>
                        <div style="margin-left: 30%;color: #0096e6;background-color: #FDE6E0;">
                            <div @click="clickName(r['c'])"
                                 style="float: left;width: 60%">
                                {{r['master']}}值{{r['v']}}两市排名{{parseInt(r['direction'])===0?'最低':'最高'}}第{{r['orderNum']}}
                            </div>

                            <div style="width: 35%; margin-left: 5%;float: right;">
                                <nut-button @click="toDetail(r['c'],r['market'])"
                                            style="margin-right: 1%;float: right"
                                            color="#7232dd"
                                            plain>
                                    行情
                                </nut-button>
                            </div>
                        </div>
                    </template>
                </nut-cell>
                <nut-backtop></nut-backtop>
            </nut-infiniteloading>
        </ul>

        <nut-overlay v-model:visible="urlShow" :z-index="2000" :close-on-click-overlay="false">
            <nut-navbar
                    style="height:40px;display: block;margin-bottom: 0"
            >
                <template #left>
                    <nut-cell title="返回" @click="titleClick"></nut-cell>
                </template>
                <template #content>
                    <nut-radiogroup @change="changeSource" v-model="detailSource" direction="horizontal">
                        <nut-radio label="1">同</nut-radio>
                        <nut-radio label="2">东</nut-radio>
                        <nut-radio label="3">雪</nut-radio>
                    </nut-radiogroup>
                </template>
                <template #right>
                    <nut-button style="height: 30px;width: auto;" @click="iback"
                                shape="square" type="primary" icon="refresh2">重置
                    </nut-button>
                </template>
            </nut-navbar>
            <nut-empty image-size="80%" style="margin-top:-40px;width:100%;height:100%;position:absolute;z-index: -999;"
                       image="empty" description="出错了,请点击上方重置按钮">
            </nut-empty>
            <iframe id="myiframe" :src="this.ifsrc" width="100%" :height="iframeHeight"
                    :style="'margin-top: '+this.ifMaginTop+'px;'"
                    scrolling="auto" security="restricted" sandbox="allow-scripts allow-same-origin allow-popups"
                    frameborder="no" border="0" marginwidth="0" marginheight="0" allowtransparency="no">
            </iframe>
        </nut-overlay>
    </div>
</template>

<script>
    import {ref} from 'vue';
    import {Toast} from '@nutui/nutui';
    import axios from "axios";
    import allMapping from '../const/introduceMapping'

    export default {
        name: "",
        props: {
            t: String,
            tIcon: String,
            busType: String,
            busName: String,
        },

        mounted() {
            this.h = document.documentElement.clientHeight - this.$refs.element.offsetHeight
            this.days = allMapping['dayRankingMapping'][this.busName]
            this.preList = allMapping['rankingMapping'][this.busName]
            if (this.busName === 'arbr') {
                this.preMaster = this.preList[0]
                this.masterNums = this.days[4]
            }
            if (this.busName === 'bias') {
                this.masterNums = this.days[1]
            }
            if (this.busName === 'cci') {
                this.masterNums = this.days[2]
            }
            if (this.busName === 'dma') {
                this.preMaster = this.preList[0]
                this.masterNums = this.days[1][0]
            }
            if (this.busName === 'dpo') {
                this.preMaster = this.preList[0]
                this.masterNums = this.days[1][0]
            }
            if (this.busName === 'kdj') {
                this.masterNums = this.days[1][0]
            }
            if (this.busName === 'macd') {
                this.preMaster = this.preList[0]
                this.masterNums = this.days[3][0]
            }
            if (this.busName === 'mtm') {
                this.preMaster = this.preList[0]
                this.masterNums = this.days[2][0]
            }
            if (this.busName === 'rsi') {
                this.preMaster = this.preList[0]
                this.masterNums = this.days[4]
            }
            if (this.busName === 'trix') {
                this.preMaster = this.preList[0]
                this.masterNums = this.days[1][0]
            }
            if (this.busName === 'wr') {
                this.preMaster = this.preList[0]
                this.masterNums = this.days[3]
            }
            if (this.busName === 'vr') {
                this.preMaster = this.preList[0]
                this.masterNums = this.days[4]
            }
            if (this.preList.length < 2) {
                this.preMaster = this.preList[0]
                this.masterNums = this.days[1]
            }
            this.getData('mounted')

        },
        methods: {

            indexChange() {
                if (this.busType === 'ranking2') {
                    this.masterNums = this.days[1][this.preList.indexOf(this.preMaster) === 0 ? 0 : 1]
                }
                this.getData('indexChange')
            },
            directionChange(value) {
                this.getData('directionChange')
            },
            changeSource(value) {
                if (value === '1') {
                    this.ifMaginTop = -40
                } else if (value === '2') {
                    this.ifMaginTop = -63
                } else {
                    this.ifMaginTop = -57
                }
                this.iback()
            },
            getIfSrc(isSelf) {
                var res
                if (isSelf) {
                    res = 'http://' + document.domain + ':' + window.location.port + '/bd?code=' + this.currentCode + '&market=' + this.currentMarket + '&detailSource=' + this.detailSource
                    return res
                }
                if (this.detailSource === '1') {
                    res = ('https://m.10jqka.com.cn/stockpage/hs_' + this.currentCode + '/#&atab=geguNews')
                } else if (this.detailSource === '2') {
                    res = ('https://wap.eastmoney.com/quote/stock/' + this.currentMarket + '.' + this.currentCode + '.html')
                } else {
                    res = ('https://xueqiu.com/S/' + (this.currentMarket === 1 ? 'SH' : 'SZ') + this.currentCode)
                }
                return res
            },
            titleClick() {
                this.urlShow = false
                this.currentCode = ''
                this.currentMarket = ''
                this.ifsrc = ''
            },
            iback() {
                if (this.ifsrc.includes(document.domain + ':' + window.location.port)) {
                    this.ifsrc = this.getIfSrc(false)
                } else {
                    this.ifsrc = this.getIfSrc(true)
                }
            },
            loadMore(done) {
                this.addData(done)
            },
            getTitleDay(day) {
                return day.toString().substr(0, 4) + '年' + day.toString().substr(4, 2) + '月' + day.toString().substr(6, 2) + '日'
            },

            addtionCode(code) {
                while (code.toString().length < 6) {
                    code = '0' + code
                }
                return code
            },
            getDataParams() {
                return {
                    dataType: this.dataType,
                    busType: this.busName + '_r',
                    date: this.dateChoose,
                    currentDate: this.day,
                    period: this.period,
                    master: this.preMaster + this.masterNums,
                    page: this.page,
                    direction: this.directionDir
                };
            }
            ,
            addData(done) {
                Toast.loading('数据加载中', {
                    duration: 10000,
                    id: 'loading'
                });
                this.isLoading = true
                this.page += 1
                let p = this.getDataParams()
                axios({method: "post", url: "/rank/getRankingStock", params: p}).then(
                    (res) => {
                        this.isLoading = false
                        Toast.hide('loading')
                        if (res.data.code === 0) {
                            if (res.data.data.rows.length > 0) {
                                this.rows = this.rows.concat(res.data.data.rows)
                                this.day = parseInt(res.data.data.day)
                                this.today = parseInt(res.data.data.today)
                                this.total = parseInt(res.data.data.total)
                                this.currentDate = new Date(parseInt(this.day / 10000), parseInt((this.day % 10000) / 100), parseInt(this.day % 100))
                                this.maxDay = new Date(parseInt(this.today / 10000), parseInt((this.today % 10000) / 100) - 1, parseInt(this.today % 100))
                                this.title = this.getTitleDay(this.day) + this.preMaster + this.masterNums + (parseInt(this.directionDir) === 0 ? '最低' : '最高') + '排行'
                            } else {
                                this.hasMore = false
                            }
                            done()
                            // Toast.text(this.page);
                        } else {
                            Toast.text(res.data.msg);
                            done()
                        }
                    }
                );
            }
            ,
            getData(channel) {
                let p = this.getDataParams();
                console.log(channel)
                if (JSON.stringify(p) !== this.paramsTemp) {
                    this.paramsTemp = JSON.stringify(p)
                    console.log(JSON.stringify(p))
                    Toast.loading('数据加载中', {
                        duration: 10000,
                        id: 'loading'
                    });
                    this.isLoading = true
                    this.page = 1
                    this.hasMore = true
                    axios({method: "post", url: "/rank/getRankingStock", params: p}).then(
                        (res) => {
                            this.isLoading = false
                            Toast.hide('loading')
                            this.chooseV = []
                            if (res.data.code === 0) {
                                this.rows = res.data.data.rows
                                this.day = parseInt(res.data.data.day)
                                this.today = parseInt(res.data.data.today)
                                this.total = parseInt(res.data.data.total)
                                this.currentDate = new Date(parseInt(this.day / 10000), parseInt((this.day % 10000) / 100) - 1, parseInt(this.day % 100))
                                this.desc = parseInt(this.day / 10000) + '年' + parseInt((this.day % 10000) / 100) + '月' + parseInt(this.day % 100) + '日'
                                this.maxDay = new Date(parseInt(this.today / 10000), parseInt((this.today % 10000) / 100) - 1, parseInt(this.today % 100))
                                this.title = this.getTitleDay(this.day) + this.preMaster + this.masterNums + (parseInt(this.directionDir) === 0 ? '最低' : '最高') + '排行'
                                Toast.text(this.title);
                            } else {
                                Toast.text(res.data.msg);
                            }
                        }
                    );
                }
            },

            toDetail(code, market) {
                this.currentCode = this.addtionCode(code)
                this.currentMarket = market
                this.ifsrc = this.getIfSrc(false)
                this.urlShow = true

            },
            clickName(value) {
                this.$copyText(value).then(function () {
                    Toast.text('股票代码' + value + '成功复制到剪贴板')
                })
            },
            back() {
                this.$router.push({path: "/yz"});
            },
            paramChange() {
                this.getData('paramChange')
            },
            typeChange() {
                this.getData('typeChange')
            }
            ,
            unitChange(value) {
                if (value === '1') {
                    this.unit = '日'
                } else if (value === '2') {
                    this.unit = '周'
                } else {
                    this.unit = '月'
                }
                this.getData('unitChange')
            }
            ,
            dateChange(value) {
                this.chooseShow = value === '3';
                if (!this.chooseShow) {
                    this.getData('dateChange')
                }
            },
            confirm({selectedValue, selectedOptions}) {
                this.desc = selectedOptions.map((option) => option.text).join('');
                this.day = parseInt(selectedValue.toString().replaceAll(',', ''))
                this.getData('confirm')
            }
        },
        data() {
            return {
                page: 1,
                period: 1,
                nums: 5,
                maxDay: new Date(),
                dateChoose: 1,
                chooseShow: false,
                ndShow: false,
                hasMore: true,
                day: '',
                today: '',
                days: [],
                desc: '',
                unit: '日',
                columns: [],
                currentDate: '',
                title: '',
                rows: [],
                total: 0,
                h: 0,
                urlShow: false,
                ifsrc: '',
                iframeHeight: document.documentElement.clientHeight,
                currentCode: '',
                currentMarket: '',
                detailSource: '1',
                ifMaginTop: -40,
                isLoading: false,
                showMaster: false,
                masterNums: '',
                guest: 5,
                chooseVisible: false,
                directionDir: 0,
                chooseV: [],
                preMaster: '',
                preList: [],
                paramsTemp: '',
                doubleTemp: [],
                dataType: 'stocks'
            }
        }
    }
</script>

<style scoped>
    .flex-content {
        line-height: 40px;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        background: #ff8881;
    }

    .infiniteUl {
        height: 300px;
        width: 100%;
        padding: 0;
        margin: 0;
        overflow-y: auto;
        overflow-x: hidden;
        background-color: #FFF2E2;

    }

    .infiniteLi {
        margin-top: 10px;
        font-size: 14px;
        color: rgba(100, 100, 100, 1);
        text-align: center;
    }
</style>
