const ovMapping = {
    'avg': ['均线简介', '移动平均线是借助一定时期股票收盘价的移动走势,反应真实的股价变动趋势.', [
        '根据均线指标的周期长短,可分为短期均线,通常有5日,10日,20日均线;中期均线,通常有30日,60日均线;长期均线,通常有120日 ,250日均线.',
        '使用均线指标时,要了解几个常用概念.', '1.排列:均线排列分为多头排列和空头排列,均线从上到下按周期长短顺序从短期到长期方式排列的,表明市场行情处于多头状态,此排列表明多方控制着局面,后市看高,反之,称为空头排列,后市看空,下跌行情未了.',
        '2.方向:短,中,长期均线均沿同一方向向上或者向下运行,为顺向运行状态.反之,短期均线和中,长期均线方向不一致,说明短线行情为反弹或者回档,中长趋势不变.',
        '3.缠绕:当短,中,长期均线相互交错,不断上下交换位置,说明行情处于盘整状态,方向不明,待方向明朗后,再做买卖决定.',
        '4.交叉:短期均线向上有效穿过中,长期均线,为金叉,反之,则称为死叉.',
        '5.形态:短期均线由下向上穿过中,长期均线,中期均线也由下向上穿过长期均线,长期均线向上运行,形成一个箭头朝上的不规则三角形,称为银三角,如果此特征连续2次出现,则称为金三角.反之,则称为死亡谷',
    ], require('../background/icons/avg.svg')],
    'arbr': ['ARBR指标简介', 'AR指标叫气势指标，BR指标叫人气指标，它们是衡量市场上多空双方力量对比变化的最重要指标.', [
        'AR指标是反映市场当前情况下多空双方力量发展对比的结果.它是以当日的开盘价为基点。与当日最高价相比较，依固定公式计算出来的强弱指标。应用法则如下:',
        '1.AR在100附近是买卖的均衡状态，手中持股安全。AR在80～120是盘整状态，沒有太大的波动。AR在50以下，表示进入超卖区，可买入股票,AR在150以上则进入超买區，注意回调，为卖出时机。',
        '2.BR指标是反映当前情况下多空双方力量争斗的结果.它是以前一日的收盘价为基础与当日的最高价、最低价相比较依固定公式计算出来的强弱指标',
        '3.BR急速股票上升，AR股票盘整小回时，应逢高卖出，及时了结',
        '4.BR比AR低，且指标处在150至100以下的区域，股票投资者可考虑逢低买进',
        '5.当AR和BR从底部上扬–段时间后，到达一-定高位并停滯不涨或开始掉头时，意味着股价已到达高位，持股者应注意及时获利了结。',
        '6.AR和BR同时从低位向上攀升，表明市场上人气开始积聚，多头力量开始占优势，股价将继续上涨，投资者可及时买人或持筹待涨。'
    ], require('../background/icons/arbr.svg')],
    'asi': ['ASI振动升降指标', 'ASI指标以开盘、最高、最低、收盘价与前一交易日的各种价格相比较作为计算因子，研判市场的方向性。', [
        '1.ASI走势几乎和股价同步,若ASI领先股价,提早突破前次ASI高点或低点,则次一日之后股价必然能突破前次高点或低点。',
        '2.向上爬升的 ASI,一旦向下跌破前一次显着的N型转折点,一律可视为停损卖出的讯号。',
        '3.股价走势一波比一波高,而ASI却未相对创新高点形成“牛背离”时,应卖出。股价走势一波比一波低,而ASI却未相对创新低点形成“熊背离”时,应买进。',
        '4.ASI大部分时机都是和股价走势同步的,投资人仅能从众多股票中,寻找少数产生领先突破的个股。投资人根据ASI早一步买入股票,随后股价顺利突破压力,一旦产生利润时,不可想象往后还有多少涨幅,应立即脱手卖出获利。',
        '5.ASI和OBV同样维持“Ｎ”字型的波动,并且也以突破或跌破“Ｎ”型高、低点,为观察ASI的主要方法。'
    ], require('../background/icons/asi.svg')],
    'bbi': ['BBI多空指标简介', 'BBI指标又叫多空指标，是一种将不同时间周期移动平均线加权平均之后的综合指标，属于均线型指标。', [
        '1.股价在高价区以收市价向下跌破BBI为卖出信号。股价在低价区以收市价向上突破BBI为买入信号。',
        '2.BBI由下向上递增，股价在BBI上方，表明多头势强，可以继续持股。BBI由上向下递减，股价在BBI下方，表明空头势强，一般不宜买入。'
    ], require('../background/icons/bbi.svg')],
    'bias': ['BIAS乖离率简介', 'BIAS是由移动平均原理派生出来的技术指标，通过测算股价在波动过程中与移动平均线出现偏离的程度，得出股价在剧烈波动时因偏离移动平均趋势而造成回档或反弹的可能', [
        '1.6日BIAS>+6,12日BIAS>8,24日BIAS>+10是卖出时机;',
        '2.6日BIAS< -6,12日BIAS< -8,24日BIAS< -10是买入时机;',
        '3.若股价大于平均线，为正乖离；股价小于平均线，则为负乖离。当股价与平均线相等时，则乖离率为零。正乖离率越大，表示短期超级入手单越大，越有可能见顶；负乖离率越大，表示短期超级抛出单越大，则越有可能见底。',
        '4.BIAS指标的缺陷是买卖信号过于频繁，因此要与随机指标(KDJ)、布林线指标(BOLL)搭配使用。',
        '5.在大势上涨的行情中,如果出现负乖离率,可判断为回档行情,在股票下跌的时候买进,风险较小,如在大势下跌行情中,如果出现正乖离率可判断为反弹行情,在股价上升时卖出,'
    ], require('../background/icons/bias.svg')],
    'boll': ['BOLL布林通道带简介', '布林线是根据统计学中的标准差原理设计出来的分析指标,股价一般会保持运行在上轨与下轨之间.', [
        '1.当布林线的上、中、下轨线同时向上运行时，表明股价非常强势，短期内将继续上涨，应坚决持股待涨或逢低买入。当布林线的上、中、下轨线同时向下运行时，表明股价非常弱势，短期内将继续下跌，应坚决持币观望或逢高卖出。',
        '2.当价格突破中轨线后,运行方向持续向上,则预示股价强势依旧,短期内还将上涨,如果价格掉落中轨线,则预示股价转为弱势',
        '3.股价上涨到布林上轨以上，代表股票开始激烈的向上波动，后期继续上涨的概率较大，股价跌破布林线下轨代表股价开始激烈的向下波动，后期继续下跌的概率比较大。',
    ], require('../background/icons/boll.svg')],
    'cci': ['CCI顺势指标简介', 'CCI指标又叫顺势指标，主要是在超买和超卖区域发生作用，对急涨急跌的行情检测性相对准确。', [
        'CCI指标就是专门针对极端情况设计的，也就是说，在一般常态行情下，CCI指标不会发生作用。CCI主要是在超买和超卖区域发生作用，对急涨急跌的行情检测性相对准确。',
        '1.当CCI指标曲线在+100线～-100线的常态区间里运行时，CCI指标参考意义不大',
        '2.当CCI指标曲线从上向下突破+100线而重新进入常态区间时，表明市场价格的上涨阶段可能结束，将进入一个比较长时间的震荡整理阶段，应及时平多做空。',
        '3.当CCI指标曲线从上向下突破-100线而进入另一个非常态区间（超卖区）时，表明市场价格的弱势状态已经形成，将进入一个比较长的寻底过程，可以持有空单等待更高利润。CCI指标曲线在超卖区运行的时间越长，确认短期的底部的准确度越高。',
        '4.CCI指标曲线从下向上突破-100线而重新进入常态区间时，表明市场价格的探底阶段可能结束，有可能进入一个盘整阶段，可以逢低少量做多。',
        '5.CCI指标曲线从下向上突破+100线而进入非常态区间(超买区)时，表明市场价格已经脱离常态而进入强势状态，如果伴随较大的市场交投，应及时介入成功率将很大。',
    ], require('../background/icons/cci.svg')],
    'dma': ['DMA平行线差指标', 'DMA指标又叫平行线差指标，是目前股市分析技术指标中的一种中短期指标。', [
        '属于趋向类指标，也是一种趋势分析指标，它是根据快慢两条移动平均线的差值情况分析价格趋势的一种技术分析指标。主要通过计算两条基准周期不同的移动平均线的差值，来判断当前买入卖出的能量的大小和未来价格走势的趋势。',
        '1.DMA线向上交叉AMA线，买进；DMA线向下交叉AMA线，卖出。',
        '2.当DMA和AMA均>0（即在图形上表示为它们处于零线以上）并向上移动时，一般表示为股市处于多头行情中，为买入信号，可以买入或持股；当DMA和AMA均<0（即在图形上表示为它们处于零线以下）并向下移动时，一般表示为股市处于空头行情中，为卖出信号，可以卖出股票或观望。',
        '3.当DMA和AMA均<0时，经过一段时间的下跌后，如果两者同时从低位向上移动时，为买进信号；当DMA和AMA均>0，在经过一段时间的上涨后，如果两者同时从高位向下移动时，为卖出信号。',
        '4.DMA指标与股价产生背离时的交叉信号，可信度较高。',
        '5.DMA指标、MACD指标、TRIX指标三者构成一组指标群，互相验证。',
    ], require('../background/icons/dma.svg')],
    'dmi': ['DMI动向指标', 'DMI指标是属于判断趋势的指标，是由多空双方力量的变化受到价格波动的影响，从而发生从均衡到失衡的循环过程，根据这个过程提前对多空趋势进行判断。', [
        '1.+DI在-DI上方,股票行情以上涨为主;+DI在-DI下方，股票行情以下跌为主。',
        '2.在股票价格上涨行情中，当+DI向上交叉-DI，是买进信号，相反,当+DI向下交叉-DI，是卖出信号。',
        '3.-DI从20以下上升到50以上,股票价格很有可能会有一波中级下跌行情。+DI从20以下上升到50以上,股票价格很有可能会有一波中级上涨行情。',
        '3.+DI和-DI以20为基准线上下波动时，该股票多空双方拉锯战,股票价格以箱体整理为主。',
        '4.当ADX在50以上反转向下，不管股票价格是上涨还是下跌,都即将反转。',
        '5.当ADX下穿ADXR形成死叉时股票上涨行情将终结,如果ADX和ADXR下行至20左右并交织波动时，说明股票将横盘整理,没有上涨行情',
        '6.当ADX上穿ADXR发生金叉时，股票将出现一波上涨行情，ADX和ADXR上行至80以上时，那么市场将很有可能是翻倍以上的大行情。'
    ], require('../background/icons/dmi.svg')],
    'dpo': ['DPO区间震荡线简介', 'DPO试图通过扣除前期移动平均价来消除长期趋势对价格波动的干扰，从而便于发现价格短期的波动和超买超卖水平。', [
        '1.DPO>0时,表明市场处于多头发展,当负值向上穿越0轴时,形成金叉,就是买入的信号,相反DPO的正值向下穿越0轴时,就是卖出的信号。',
        '2.DPO在低档出现第二次向上穿越MADPO时，股价上涨幅度会较大;DPO在高档出现第二次向下交叉MADPO时，股价下跌幅度较深。',
        '3.2、在0轴上设置超买线，DPO起伏到超买线，意味着股价处于短期高点。',
        '4.在低于0轴的地方，设置超卖线，DPO当波动到超卖线时，意味着股价处于短期低点。',
        '5.超买超卖的范围因个股而异，用户可自行调整。',
    ], require('../background/icons/dpo.svg')],
    'kdj': ['KDJ随机指标简介', 'KDJ指标利用真实的价格波动来反映市场上买卖双方力量的对比，其特点是能够比较迅速、直观地判断行情。', [
        '1.金叉出现时KDJ指标的位置越低，未来股价上涨空间就越大。若在金叉出现时D线位于超卖区间，金叉完成D线也突破了超卖区间，则该形态看涨信号会更加强烈。',
        '2.K值在50以下接连两次上穿D值，形成右底比左底高的“W底”形态，后市可能会有较大涨幅。K值在50以上接连两次下穿D值，形成右头比左头低的“M头”形态，后市股价可能会有较大跌幅。',
        '3.K线与D线的交叉突破在80以上或20以下时较准确。当交叉在50左右发生时，表明市场陷入盘局，在寻找突破方向。此时，交叉突破提供的买卖信号无效。',
        '4.K线是快速确认线,在90以上为超买，10以下为超卖。D线是慢速主干线,在80以上为超买，20以下为超卖。',
        '5.K、D、J值都大于50时，后市看涨；K、D、J值都小于50时，后市看空。',
    ], require('../background/icons/kdj.svg')],
    'mtm': ['MTM动量指标简介', 'MTM动量指数以分析股价波动的速度为目的，研究股价在波动过程中各种加速，减速，惯性作用以及股价由静到动或由动转静的现象。', [
        '1.MTM向下跌破中心线时为卖出时机，MTM向上突破中心线时为买进时机。',
        '2.股价在上涨行情中创出高点，而MTM未能配合上升，出现背驰现象，意味上涨动力减弱，此时应关注行情，慎防股价反转下跌。',
        '3.股价在下跌行情中走出新低点，而MTM未能配合下降，出现背驰，该情况意味下跌动力减弱，此时应注意逢低承接。',
        '4.若股价与MTM在低位同步上升，显示短期将有反弹行情；若股价与MTM在高位同步下降，则显示短期可能出现股价回落。',
    ], require('../background/icons/mtm.svg')],
    'obv': ['OBV能量潮指标', 'OBV指标通过统计成交量变动的趋势来推测股价趋势', [
        '1.当股价上升而OBV线下降，表示买盘无力，股价可能会回跌。',
        '2.股价下降时而OBV线上升，表示买盘旺盛，逢低接手强股，股价可能会止跌回升。',
        '3.OBV线缓慢上升，表示买气逐渐加强，为买进信号。OBV线急速上升时，表示力量将用尽为卖出信号。',
        '4.OBV线从正的累积数转为负数时，为下跌趋势，应该卖出持有股票。反之，OBV线从负的累积数转为正数时，应该买进股票。',
        '5.OBV线变动方向是重要参考指数，其具体的数值并无实际意义。'
    ]],
    'rsi': ['RSI相对强弱指标', 'RSI是根据一定时期内上涨和下跌幅度之和的比率制作出的一种技术曲线。', [
        '1.RSI=50为强势市场与弱势市场分界点。通常设RSI>80为超买区，市势回挡的机会增加；RSI<20为超卖区，市势反弹的机会增加。',
        '2.RSI掉头向下为卖出讯号，RSI掉头向上为买入信号。但应用时宜从整体态势的判断出发。',
        '3.多头市场,RSI超过70后若股价回撤,RSI指标将会很难低于30,在空头市场,一旦RSI回落30以下,将会很难再升到70以上。',
        '4.短期RSI值在20以下，由下向上交叉黄色的长期RSI值时为买入信号。短期RSI值在80以上，由上向下交叉黄色的长期RSI值时为卖出信号。',
        '5.短期RSI值由上向下突破50，代表股价已经转弱。短期RSI值由下向上突破50，代表股价已经转强。',
    ], require('../background/icons/rsi.svg')],
    'trix': ['TRIX三重指数平滑平均线', 'TRIX指标是对平均线进行三次平滑处理，再根据移动平均线的变动情况来预测股价的长期走势。', [
        '1.当TRIX线从下向上突破TRMA线形成“金叉”,TRIX线和TRMA线同时向上运动时，预示着股价进入强势阶段。',
        '2.当TRIX线在高位向下突破TRMA线形成“死叉”，TRIX线和TRMA线同时向下运动时，预示着股价上涨行情已经结束。',
        '3.TRIX线在TRMA下方向下运动很长时间且股价已有较大的跌幅时，若TRIX线在底部有走平或向上勾头迹象时，一旦股价大成交量向上攀升，投资者可以中线建仓。',
        '4.TRIX与股价产生背离时，应注意随时会反转。',
        '5.TRIX曲线顶部反转形态对行情判断的准确性要高于底部形态，TRIX指标不适用于对股价的盘整行情的研判',
    ], require('../background/icons/trix.svg')],
    'vr': ['VR成交量变异率指标', 'VR通过分析股票下跌日的股票上升日的股票上升日的交易量与交易量的比例来表达市场中的长和短的力量。', [
        '1.VR指标值分为四个部分：低价区域（40～70）、安全区域（80～150）、获利区域（160～350）和警戒区域（350以上）。',
        '2.VR<40，市场易形成底部，应积极买入；VR指标在“低价区域”时，可考虑跟进；在“安全区域”时，股价波动小，可以持有。',
        '3.VR一般分布在150左右最多。若VR指标长期在150附近盘整，一旦突破250时,极易产生一段多头行情；当VR>450，市场交易过热，应卖出。',
        '4.当成交量极度萎缩后放大，VR指标也从低价区向上递增时，是买进时机。VR指标在低价区的买入信号可信度较高，而观察高价区时应与其他指标结合使用。',
    ], require('../background/icons/vr.svg')],
    'wr': ['WR威廉指标简介', 'WR指标根据股票价格的波动点来衡量股票是超买还是超卖。它是某段时间内由多头和空头双方创造的峰值与每天收盘价之间的距离与股价波动幅度的比值。', [
        '1.当威廉指数线高于85，市场处于超卖状态，行情即将见底。当威廉指数线低于15，市场处于超买状态，行情即将见顶。',
        '2.WR进入高位后，一般要回头，如果股价继续上升就产生了背离，是卖出信号。WR进入低位后，一般要反弹，如果股价继续下降就产生了背离。是买入信号。',
        '3.W&R连续几次撞顶（底），局部形成双重或多重顶（底），是卖出（买进）的信号。',
        '4.与RSI指数配合使用，可得出对大市走向较为准确的判断。',
    ], require('../background/icons/wr.svg')]
}
const dyzMapping = [
    [
        'common', '常用多因子组合'
    ],
    [
        'custom', '自定义多因子组合'
    ]
]

const yzMapping = [
    ['avg', '均线因子', [
        ['突破均线', require('../background/icons/runup.svg'), 'yzdetail', 'avg', 'runUp'],
        ['均线金叉', require('../background/icons/jincha.svg'), 'yzdetail', 'avg', 'access'],
        ['均线上扬', require('../background/icons/shangyang.svg'), 'yzdetail', 'avg', 'trend']
    ]
    ]
    ,
    ['arbr', 'arbr因子', [
        ['arbr低位排行', require('../background/icons/paihang.svg'), 'yzdetail', 'arbr', 'ranking'],
        // ['br低位排行', require('../background/icons/paihang.svg'), 'yzdetail'],
        ['brar金叉', require('../background/icons/line_chart_against.svg'), 'yzdetail', 'arbr', 'single'],
    ]
    ],
    ['asi', 'asi因子', [
        ['asi金叉', require('../background/icons/line_chart_against.svg'), 'yzdetail', 'asi', 'single'],
        ['asi趋势转换', require('../background/icons/nizhuan.svg'), 'yzdetail', 'asi', 'trend'],
    ]
    ],
    ['bbi', 'bbi因子', [
        ['bbi金叉', require('../background/icons/line_chart_against.svg'), 'yzdetail', 'bbi', 'non'],
        ['bbi趋势转换', require('../background/icons/nizhuan.svg'), 'yzdetail', 'bbi', 'trend'],
    ]
    ],
    ['bias', 'bias因子', [
        ['bias超跌', require('../background/icons/chaodie.svg')],
        ['bias低位排行', require('../background/icons/paihang.svg'), 'yzdetail', 'bias', 'ranking'],
        // ['bias金叉', require('../background/icons/line_chart_against.svg'), 'abc'],
    ]
    ], ['boll', 'boll因子', [
        ['boll超跌排行', require('../background/icons/paihang.svg'), 'abc'],
        ['boll突破', require('../background/icons/runup.svg'), 'yzdetail', 'boll', 'runUp']
    ]
    ], ['cci', 'cci因子', [
        ['cci超跌排行', require('../background/icons/paihang.svg'), 'yzdetail', 'cci', 'ranking'],
        ['cci突破', require('../background/icons/runup.svg'), 'abc']
    ]
    ], ['dma', 'dma因子', [
        ['dma金叉', require('../background/icons/line_chart_against.svg'), 'yzdetail', 'dma', 'double'],
        ['dma突破', require('../background/icons/runup.svg'), 'yzdetail', 'dma'],
        ['dma超跌排行', require('../background/icons/paihang.svg'), 'yzdetail', 'dma', 'ranking2'],
        ['dma趋势转换', require('../background/icons/runup.svg'), 'yzdetail', 'dma', 'trend2'],
    ]
    ], ['dmi', 'dmi因子', [
        ['dmi金叉', require('../background/icons/line_chart_against.svg'), 'yzdetail', 'dmi', 'double'],
        ['dmi趋势转换', require('../background/icons/nizhuan.svg'), 'abc'],
    ]
    ], ['dpo', 'dpo因子', [
        ['dpo金叉', require('../background/icons/line_chart_against.svg'), 'yzdetail', 'dpo', 'double'],
        ['dpo突破', require('../background/icons/runup.svg'), 'abc'],
        ['dpo超跌排行', require('../background/icons/paihang.svg'), 'yzdetail', 'dpo', 'ranking2'],
        ['dpo趋势转换', require('../background/icons/nizhuan.svg'), 'yzdetail', 'dpo', 'trend'],
    ]
    ], ['kdj', 'kdj因子', [
        ['KDJ超卖', require('../background/icons/chaodie.svg'), 'abc'],
        ['KDJ超跌排行', require('../background/icons/paihang.svg'), 'yzdetail', 'kdj', 'ranking'],
        ['KDJ金叉', require('../background/icons/line_chart_against.svg'), 'yzdetail', 'kdj', 'single']
    ]
    ]
    , [
        'macd', 'macd因子', [
            ['MACD金叉', require('../background/icons/line_chart_against.svg'), 'yzdetail', 'macd', 'double'],
            ['MACD超跌排行', require('../background/icons/paihang.svg'), 'yzdetail', 'macd', 'ranking2'],
            ['MACD趋势转换', require('../background/icons/nizhuan.svg'), 'yzdetail', 'macd', 'trend2']
        ]
    ]

    , ['mtm', 'mtm因子', [
        ['MTM趋势转换', require('../background/icons/nizhuan.svg'), 'yzdetail', 'mtm', 'trend'],
        ['MTM超跌排行', require('../background/icons/paihang.svg'), 'yzdetail', 'mtm', 'ranking2'],
        ['MTM金叉', require('../background/icons/line_chart_against.svg'), 'yzdetail', 'mtm', 'double']
    ]
    ],
    ['obv', 'obv因子', [
        ['obv趋势转换', require('../background/icons/nizhuan.svg'), 'yzdetail', 'obv', 'trend']
    ]
    ]
    ,
    ['rsi', 'rsi因子', [
        ['RSI突破', require('../background/icons/runup.svg'), 'abc'],
        ['RSI超跌排行', require('../background/icons/paihang.svg'), 'yzdetail', 'rsi', 'ranking'],
        ['RSI趋势转换', require('../background/icons/nizhuan.svg'), 'yzdetail', 'rsi', 'trend'],
        ['RSI金叉', require('../background/icons/line_chart_against.svg'), 'abc']
    ]
    ], ['trix', 'trix因子', [
        ['TRIX突破', require('../background/icons/runup.svg'), 'abc'],
        ['TRIX超跌排行', require('../background/icons/paihang.svg'), 'yzdetail', 'trix', 'ranking2'],
        ['TRIX趋势转换', require('../background/icons/nizhuan.svg'), 'yzdetail', 'trix', 'trend2'],
        ['TRIX金叉', require('../background/icons/line_chart_against.svg'), 'yzdetail', 'trix', 'double']
    ]
    ], ['vr', 'vr因子', [
        ['VR突破', require('../background/icons/runup.svg'), 'abc'],
        ['VR趋势转换', require('../background/icons/nizhuan.svg'), 'yzdetail', 'vr', 'trend'],
        ['VR超跌排行', require('../background/icons/paihang.svg'), 'yzdetail', 'vr', 'ranking'],
    ]
    ], ['wr', 'wr因子', [
        ['WR排行', require('../background/icons/paihang.svg'), 'abc'],
        ['WR超跌排行', require('../background/icons/paihang.svg'), 'yzdetail', 'wr', 'ranking'],
    ]
    ]
]


const daysAccessMapping = {
    'avg': [5, 10, 12, 20, 26, 30, 60],
    'arbr': [10, 14, 18, 22, 26, 36, 48],
    'asi': [10, 18, 26, 36, 48, 60],
    'bbi': [1],
    'boll': [10, 12, 16, 20, 26, 40, 60],
    'kdj': [6, 9, 12, 18, 24],
    'dma': [JSON.stringify(['5_26', '5_26_5']), JSON.stringify(['10_50', '10_50_10']),
        JSON.stringify(['12_60', '12_60_12']), JSON.stringify(['18_60', '18_60_18']),
        JSON.stringify(['20_60', '20_60_20']), JSON.stringify(['26_60', '26_60_26']),
        JSON.stringify(['36_60', '36_60_36']), JSON.stringify(['48_100', '48_100_48'])],
    'dmi': [JSON.stringify(['pdi_7', 'mdi_7']),
        JSON.stringify(['pdi_14', 'mdi_14']), JSON.stringify(['adx_7_3', 'adxr_7_3']),
        JSON.stringify(['adx_10_5', 'adxr_10_5']), JSON.stringify(['adx_14_6', 'adxr_14_6']),
    ],
    'dpo': [JSON.stringify(['10_5', '10_5_3']), JSON.stringify(['20_10', '20_10_6']),
        JSON.stringify(['26_13', '26_13_8']), JSON.stringify(['36_18', '36_18_11']),
        JSON.stringify(['48_24', '48_24_15']), JSON.stringify(['60_30', '60_30_18'])
    ],
    'macd': [JSON.stringify(['5_34', '5_34_7']),
        JSON.stringify(['6_13', '6_13_5']), JSON.stringify(['10_22', '10_22_9']),
        JSON.stringify(['12_26', '12_26_9']), JSON.stringify(['13_34', '13_34_5'])
    ],
    'mtm': [JSON.stringify(['6', '6_3']), JSON.stringify(['9', '9_5']),
        JSON.stringify(['12', '12_6']), JSON.stringify(['18', '18_9']), JSON.stringify(['24', '24_12']),
        JSON.stringify(['36', '36_18']), JSON.stringify(['48', '48_24'])
    ],
    'rsi': [JSON.stringify(['6', '6_3']), JSON.stringify(['9', '9_5']),
        JSON.stringify(['12', '12_6']), JSON.stringify(['18', '18_9']), JSON.stringify(['24', '24_12']),
        JSON.stringify(['36', '36_18']), JSON.stringify(['48', '48_24'])
    ],
    'trix': [JSON.stringify(['7', '7_10']), JSON.stringify(['12', '12_20']),
        JSON.stringify(['14', '14_26']), JSON.stringify(['18', '18_30']), JSON.stringify(['24', '24_12']),
        JSON.stringify(['36', '36_18']), JSON.stringify(['28', '28_48'])
    ],
}

const dayRankingMapping = {
    'arbr': [10, 14, 18, 22, 26, 36, 48],
    'bias': [6, 12, 18, 24, 36, 48],
    'cci': [7, 10, 14, 18, 21, 28],
    'dma': [['5_26', '5_26_5'], ['10_50', '10_50_10'], ['12_60', '12_60_12'], ['18_60', '18_60_18'],
        ['20_60', '20_60_20'], ['26_60', '26_60_26'], ['36_60', '36_60_36'], ['48_100', '48_100_48']],
    'dpo': [['10_50', '10_5_3'], ['20_10', '20_10_6'], ['26_13', '26_13,8'], ['36_18', '36_18_11'],
        ['48_24', '48_24_15'], ['60_30', '60_30_18']],
    'kdj': [6, 9, 12, 18,21, 24],
    'macd': [['5_34', '5_34_7'], ['6_13', '6_13_5'], ['10_22', '10_22_9'], ['12_26', '12_26_9'], ['13_34', '13_34_5']],
    'mtm': [['6', '6_3'], ['9', '9_5'], ['12', '12_6'], ['18', '18_9'], ['24', '24_12'], ['36', '36_18'], ['48', '48_24']],
    'rsi': [5, 6, 9, 10, 12, 14, 18, 24],
    'trix': [['7', '7_10'], ['12', '12_20'], ['14', '14_26'], ['18', '18_30'], ['21', '21_36'], ['28', '28_48']],
    'wr': [5, 6, 9, 10, 12, 14, 18, 24],
    'vr': [10, 12, 16, 20, 26, 40, 60],
}

const dayTrendMapping = {
    'avg': ['1', '3', '5', '9', '10', '12', '20', '26', '30', '60'],
    'asi': ['10_4', '18_7', '26_10', '36_14', '48_20', '60_24'],
    'bbi': [''],
    'dma': [['5_26', '5_26_5'], ['10_50', '10_50_10'], ['12_60', '12_60_12'],
        ['18_60', '18_60_18'], ['20_60', '20_60_20'], ['26_60', '26_60_26'], ['36_60', '36_60_36'], ['48_100', '48_100_48']],
    'dpo': ['10_5_3', '20_10_6', '26_13_8', '36_18_11', '48_24_15', '60_30_18'],
    'kdj': [6, 9, 12, 18,21, 24],
    'macd': [['5_34', '5_34_7'], ['6_13', '6_13_5'], ['10_22', '10_22_9'], ['12_26', '12_26_9'], ['13_34', '13_34_5']],
    'mtm': ['6_3', '9_5', '12_6', '18_9', '24_12', '36_18', '48_24'],
    'obv': [10, 20, 30, 40, 50, 60],
    'rsi': [5, 6, 9, 10, 12, 14, 18, 24],
    'trix': [['7', '7_10'], ['12', '12_9'], ['14', '14_26'], ['18', '18_30'], ['21', '21_36'], ['28', '28_48']],
    'vr': [10, 12, 16, 20, 26, 40, 60]
}
const trendMapping = {
    'avg': ['avg_'],
    'asi': ['asit_'],
    'bbi': ['bbi'],
    'dma': ['ddd_', 'ama'],
    'dpo': ['madpo_'],
    'kdj': ['k_', 'd_', 'j_'],
    'macd': ['dif_', 'dea_', 'macd_'],
    'mtm': ['ma_mtm_'],
    'obv': ['obv_'],
    'rsi': ['rsi_'],
    'trix': ['trix_', 'trma_'],
    'vr': ['vr_'],

}
const rankingMapping = {
    'arbr': ['ar_', 'br_'],
    'bias': ['bias_'],
    'cci': ['cci_'],
    'dma': ['ddd_', 'ama_'],
    'dpo': ['dpo_', 'ma_dpo_'],
    'kdj': ['j_'],
    'macd': ['dif_', 'dea_', 'macd_'],
    'mtm': ['mtm_', 'ma_mtm_'],
    'rsi': ['rsi_'],
    'trix': ['trix_', 'trma_'],
    'wr': ['wr_'],
    'vr': ['vr_'],
}


const mgMapping = {
    'avg': ['avg_', 'avg_'],
    'arbr': ['ar_', 'br_'],
    'asi': ['asi_', 'asit_'],
    'bbi': ['close', 'bbi'],
    'boll': ['', ''],
    'dma': ['ddd_', 'ama_'],
    'dmi': ['', ''],
    'dpo': ['dpo_', 'ma_dpo_'],
    'kdj': ['k_', 'd_'],
    'macd': ['dif_', 'dea_'],
    'mtm': ['mtm_', 'ma_mtm_'],
    'rsi': ['rsi_', 'rsi_'],
    'trix': ['trix_', 'trma_'],
}
const allMapping = {
    'yzMapping': yzMapping,
    'dyzMapping': dyzMapping,
    'ovMapping': ovMapping,
    'daysAccessMapping': daysAccessMapping,
    'dayRankingMapping': dayRankingMapping,
    'dayTrendMapping': dayTrendMapping,
    'mgMapping': mgMapping,
    'rankingMapping': rankingMapping,
    'trendMapping': trendMapping,
}
export default allMapping
