import {createRouter, createWebHistory} from 'vue-router'
import PCLogin from "../views/login/PCLogin";
import PCMain from "../views/admin/PCMain";
import PhoneLogin from "../views/login/PhoneLogin";
import PCRegist from "../views/regist/PCRegist";
import YinZiXuanGuMain from "../views/yinzi/YinZiXuanGuMain";
import DuoYinZiXuanGuMain from "../views/yinzi/DuoYinZiXuanGuMain";
import YZDetail from "../views/yinzi/YZDetail";
import DYZDetail from "../views/yinzi/DYZDetail";
import VixChats from "../views/chats/VixChats";

const routes = [
    {
        path: "",
        redirect: "/phoneLogin",
        meta: {
            title: "灿韵灵越-登录"
        }
    },
    {
        path: "/pclogin",
        name: "pclogin",
        component: PCLogin,
        meta: {
            title: "灿韵灵越-登录"
        }
    },
    {
        path: "/pcMain",
        name: "pcMain",
        component: PCMain,
        meta: {
            title: "灿韵灵越-主页"
        }
    },
    {
        path: "/phonelogin",
        name: "phonelogin",
        component: PhoneLogin,
        meta: {
            title: "灿韵灵越-登录"
        }
    },
    {
        path: "/yz",
        name: "yz",
        component: YinZiXuanGuMain,
        meta: {
            noCache: false,
            keepalive: true,
            title: "灿韵灵越-上涨因子"
        },
    },
    {
        path: "/dyz",
        name: "dyz",
        component: DuoYinZiXuanGuMain,
        meta: {
            noCache: false,
            keepalive: true,
            title: "灿韵灵越-多因子"
        },
    },
    {
        path: "/vix",
        name: "vix",
        component: VixChats,
        meta: {
            noCache: false,
            keepalive: true,
            title: "灿韵灵越-贪恐指数"
        },
    }
    ,
    {
        path: "/yzdetail",
        name: "yzdetail",
        component: YZDetail,
        meta: {
            title: "灿韵灵越-上涨因子选股"
        },
    },
    {
        path: "/dyzdetail",
        name: "dyzdetail",
        component: DYZDetail,
        meta: {
            title: "灿韵灵越-多因子选股"
        },
    },

    {
        path: "/regist",
        name: "regist",
        component: PCRegist,
    },
    {
        path: "/about",
        name: "About",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/About.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
